import React, { createContext, useContext, useRef, useEffect } from 'react';

const MetricsContext = createContext();

export const MetricsProvider = ({ children }) => {
    const metricsRef = useRef({
        total_traffic: 0,
        total_payload_size: 0,
        total_uncompressed_payload_size: 0,
        avg_payload_size: 0,
        avg_uncompressed_payload_size: 0,
        avg_payload_compression_ratio: 1,

        total_response_size: 0,
        total_uncompressed_response_size: 0,
        avg_response_size: 0,
        avg_uncompressed_response_size: 0,
        avg_response_compression_ratio: 1,

        avg_communication_time: 0,
        total_compression_time: 0,
        avg_compression_time: 0,
        total_decompression_time: 0,
        avg_decompression_time: 0,
        total_request_time: 0,
        avg_request_time: 0,

        request_history: [],
        compression_algo: process.env.REACT_APP_COMPRESSION_ALGORITHM || 'none',
    });

    // Use a ref to keep track of the previous total_traffic value
    const previousTrafficRef = useRef(metricsRef.current.total_traffic);

    const updateMetrics = (updateFunction) => {
        updateFunction(metricsRef.current);
        // No need to log here, as we will log periodically
    };

    useEffect(() => {
        const interval = setInterval(() => {
            const currentTotalTraffic = metricsRef.current.total_traffic;
            if (currentTotalTraffic !== previousTrafficRef.current) {
                console.log('Current Metrics:\n', metricsRef.current);
                previousTrafficRef.current = currentTotalTraffic;
            }
        }, 100); // Runs every 1 second

        return () => clearInterval(interval); // Cleanup on unmount
    }, []);

    return (
        <MetricsContext.Provider value={{ metricsRef, updateMetrics }}>
            {children}
        </MetricsContext.Provider>
    );
};

export const useMetrics = () => useContext(MetricsContext);
