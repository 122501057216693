// Context.js
import React, { createContext, useRef } from 'react';

export const CourseListContext = createContext();

export const CourseListProvider = ({ children }) => {
    const courseActionHistory = useRef([]);
    const courseActionStep = useRef(0);

    return (
        <CourseListContext.Provider value={{ courseActionHistory, courseActionStep }}>
            {children}
        </CourseListContext.Provider>
    );
};
