import React, { useState, useEffect } from "react";
import {
    Popover,
    Divider,
    Button,
    Row,
    Select,
    Checkbox,
    Tooltip,
    Space,
    InputNumber,
    Tabs,
    Col,
    Switch,
    Collapse,
    message,
} from "antd";
import {
    DeleteOutlined,
    FilterOutlined,
} from "@ant-design/icons";
import MultiCheckBox from "./MultiCheckBox";
import "../Stylesheets/filter.css";

const { Option } = Select;
const filterPaneWidth = 250;
const collapseWidth = 220;

const defaultEngSelectedTypes = ["kernel", "depth", "other", 'free', 'required'];
const defaultArtsciSelectedTypes = ["cs", "hss", "other"];

const defaultSelectedAreas = ["1", "2", "3", "4", "5", "6", "7", "O"];

const CEABRequirementPanel = ({ ceabRow, setCeabRow, resetKey }) => {
    const createNewFilterValue = () => ({
        id: Date.now(),
        name: "MA",
        operation: "gt",
        value: 12,
    });

    useEffect(() => {
        // Reset the state when resetKey changes
        setCeabRow([]);
    }, [resetKey]);

    const ceabValsPair = [
        ["MA", "Mathematics"],
        ["NS", "Natural Science"],
        ["ES", "Engineering Science"],
        ["ED", "Engineering Design"],
        ["CS", "Complementary Studies"],
    ];

    const ceabVals = ceabValsPair.map(([short, full]) => ({
        value: short,
        label: (
            <Tooltip title={full} placement="left">
                {short}
            </Tooltip>
        ),
    }));

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column'
        }}>
            {ceabRow.map((rowData) => (
                <div
                    key={rowData.id}
                    style={{
                        width: `${filterPaneWidth}px`,
                        display: "flex",
                        flexDirection: "row",
                        marginBottom: "5px",
                    }}
                >
                    <Space.Compact>
                        <Select
                            options={ceabVals}
                            defaultValue={rowData.name}
                            onSelect={(e) => {
                                let newAllRows = [];
                                for (let row of ceabRow) {
                                    if (rowData.id === row.id) {
                                        newAllRows.push({
                                            ...row,
                                            name: e
                                        })
                                    } else {
                                        newAllRows.push(row)
                                    }
                                }
                                setCeabRow(newAllRows);
                            }}
                            style={{
                                width: "64px",
                            }}
                        />
                        <Select
                            defaultValue={rowData.operation}
                            style={{
                                width: 51,
                            }}
                            onChange={(e) => {
                                let newAllRows = [];
                                for (let row of ceabRow) {
                                    if (rowData.id === row.id) {
                                        newAllRows.push({
                                            ...row,
                                            operation: e
                                        })
                                    } else {
                                        newAllRows.push(row)
                                    }
                                }
                                setCeabRow(newAllRows);
                            }}
                        >
                            <Option value="eq">=</Option>
                            <Option value="lt">&lt;</Option>
                            <Option value="gt">&gt;</Option>
                        </Select>
                        <InputNumber
                            min={0}
                            max={99}
                            style={{
                                width: "56px",
                            }}
                            defaultValue={rowData.value}
                            onChange={(e) => {
                                let newAllRows = [];
                                for (let row of ceabRow) {
                                    if (rowData.id === row.id) {
                                        newAllRows.push({
                                            ...row,
                                            value: e
                                        })
                                    } else {
                                        newAllRows.push(row)
                                    }
                                }
                                setCeabRow(newAllRows);
                            }}
                        />
                        <Button
                            style={{
                                width: "26px",
                            }}
                            icon={<DeleteOutlined />}
                            onClick={() => {
                                const newData = ceabRow.filter((item) => item.id !== rowData.id);
                                setCeabRow(newData);
                            }}
                        />
                    </Space.Compact>
                </div>
            ))}
            <Button
                style={{
                    // marginTop: "5px",
                }}
                onClick={() => {
                    if (ceabRow.length < 5) {
                        setCeabRow((prev) => [...prev, createNewFilterValue()]);
                    } else {
                        message.error("There can at most be 5 CEAB filters!")
                    }
                }}
            >
                Add new filter
            </Button>
        </div>
    );
};

const EngFilters = ({
    engEnabled,
    setEngEnabled,
    selectedTypes,
    setSelectedTypes,
    selectedAreas,
    setSelectedAreas,
    resetKey,
    setResetKey,
    ceabRow,
    setCeabRow
}) => {
    const filterTypeItemsData = [
        { key: "kernel", label: "Kernel" },
        { key: "depth", label: "Depth" },
        { key: "other", label: "Other" },
        { key: "free", label: "Free" },
        { key: "required", label: "Required" },
    ];

    const filterAreaItemsData = [
        {
            key: "1",
            label: "Area 1",
            full: "Photonics & Semiconductor Physics",
        },
        {
            key: "2",
            label: "Area 2",
            full: "Electromagnetics & Energy Systems",
        },
        {
            key: "3",
            label: "Area 3",
            full: "Analog & Digital Electronics",
        },
        {
            key: "4",
            label: "Area 4",
            full: "Control, Communications & Signal Processing",
        },
        {
            key: "5",
            label: "Area 5",
            full: "Computer Hardware & Computer Networks",
        },
        { key: "6", label: "Area 6", full: "Software" },
        { key: "7", label: "Area 7", full: "Science/Math Electives" },
        { key: "O", label: "Other", full: "Other" },
    ];

    const detailedSettingItems = [
        {
            key: "areaSelect",
            label: "Searched Areas",
            children: (
                <MultiCheckBox
                    key={"engAreaSelectMultiSelectBox"}
                    data={filterAreaItemsData}
                    checkall
                    value={selectedAreas}
                    onChange={setSelectedAreas}
                />
            ),
        },
        {
            key: "typesSelect",
            label: "Searched Types",
            children: (
                <MultiCheckBox
                    key={"engTypesSelectMultiSelectBox"}
                    data={filterTypeItemsData}
                    checkall
                    value={selectedTypes}
                    onChange={setSelectedTypes}
                />
            ),
        },
        // {
        //     key: "ceabFilter",
        //     label: "CEAB Filter",
        //     children: (
        //         <CEABRequirementPanel
        //             resetKey={resetKey}
        //             ceabRow={ceabRow}
        //             setCeabRow={setCeabRow}
        //         />
        //     ),
        // },
    ];

    return (
        <div>
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginBottom: "6px",
                    width: `${collapseWidth}px`,
                }}
            >
                <span>Search Enabled: </span>
                <Switch
                    checkedChildren="On"
                    unCheckedChildren="Off"
                    checked={engEnabled}
                    onChange={(e) => {
                        setEngEnabled(e);
                    }}
                />
            </div>

            {engEnabled ? (
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <Collapse
                        accordion
                        style={{
                            width: `${collapseWidth}px`,
                        }}
                        size="small"
                        items={detailedSettingItems}
                    />
                </div>
            ) : <div
                style={{
                    width: `${collapseWidth}px`,
                }}
            >
                <Divider></Divider>
                <span style={{
                    textAlign: 'center',
                    color: 'red',
                    fontWeight: 'bold',
                    width: `${collapseWidth}px`,
                }}>
                    Search will no longer include engineering courses.
                </span>
            </div>
            }
        </div>
    );
};

const ArtsciFilters = ({
    artsciEnabled,
    setArtsciEnabled,
    approvedOnly,
    setApprovedOnly,
    artsciSelectedTypes,
    setArtsciSelectedTypes,
}) => {
    const filterTypeItemsData = [
        { key: "cs", label: "CS" },
        { key: "hss", label: "HSS" },
        { key: "other", label: "Other" },
    ];

    const detailedSettingItems = [
        {
            key: "typesSelect",
            label: "Searched Types",
            children: (
                <MultiCheckBox
                    key={"ArtSciAreaSelectMultiSelectBox"}
                    data={filterTypeItemsData}
                    checkall
                    value={artsciSelectedTypes}
                    onChange={setArtsciSelectedTypes}
                />
            ),
        }
    ];

    return (
        <div>
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginBottom: "6px",
                    width: `${collapseWidth}px`,
                }}
            >
                <span>Search Enabled: </span>
                <Switch
                    checkedChildren="On"
                    unCheckedChildren="Off"
                    checked={artsciEnabled}
                    onChange={(e) => {
                        setArtsciEnabled(e);
                    }}
                />
            </div>

            {artsciEnabled ? (
                <div>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between",
                            marginBottom: "6px",
                            width: `${collapseWidth}px`,
                        }}
                    >
                        <span>ECE Approved Only: </span>
                        <Switch
                            checkedChildren="On"
                            unCheckedChildren="Off"
                            checked={approvedOnly}
                            onChange={(e) => {
                                setApprovedOnly(e);
                            }}
                        />
                    </div>

                    <Collapse
                        accordion
                        style={{
                            width: `${collapseWidth}px`,
                        }}
                        size="small"
                        items={detailedSettingItems}
                    />
                </div>
            ) : <div
                    style={{
                        width: `${collapseWidth}px`,
                    }}
                >
                    <Divider></Divider>
                    <span style={{
                        textAlign: 'center',
                        color: 'red',
                        fontWeight: 'bold',
                    }}>
                        Search will no longer include art-sci courses.
                    </span>
                </div>
            }
        </div>
    );
}

const FilterPanel = ({ style = {}, children }) => {
    const [selectedTerms, setSelectedTerms] = useState(["fall", "winter"]);
    const [avoidConflict, setAvoidConflict] = useState(false);

    const [numReturn, setNumReturn] = useState(5);

    const [engEnabled, setEngEnabled] = useState(true);
    const [artsciEnabled, setArtsciEnabled] = useState(true);

    // Eng Filters
    const [selectedTypes, setSelectedTypes] = useState(["kernel", "depth", "other", 'free', 'required']);
    const [artsciSelectedTypes, setArtsciSelectedTypes] = useState(["cs", "hss", "other"]);

    const [selectedAreas, setSelectedAreas] = useState(defaultSelectedAreas);
    const [resetKey, setResetKey] = useState(0);
    const [ceabRow, setCeabRow] = useState([]);

    // Artsci Filters
    const [approvedOnly, setApprovedOnly] = useState(false);

    const termOptions = [
        {
            label: 'F',
            value: 'fall',
        },
        {
            label: 'S',
            value: 'winter',
        },
        {
            label: 'Y',
            value: 'yearly',
        }

    ];

    const filterData = {
        semester: selectedTerms,

        avoid_conflict: avoidConflict,
        eng_enabled: engEnabled,
        artsci_enabled: artsciEnabled,
        numReturn: numReturn,
        ceab: ceabRow.map((item) => {
            const namesArray = ['MA', 'NS', 'CS', 'ES', 'ED'];
            return {
                name: namesArray.indexOf(item.name),
                operation: item.operation,
                value: item.value
            }
        }),
        eng: {
            area: selectedAreas,
            type: selectedTypes,
        },
        artsci: {
            approvedOnly: approvedOnly,
            type: artsciSelectedTypes,
        }
    };

    // useEffect(()=>{
    //     console.log('Filter panel:', filterData)
    // }, [selectedTypes])

    const EngArtsciTabItems = [
        {
            key: "EngineeringTab",
            label: "Engineering",
            children: (
                <EngFilters
                    engEnabled={engEnabled}
                    setEngEnabled={setEngEnabled}
                    selectedTypes={selectedTypes}
                    setSelectedTypes={setSelectedTypes}
                    selectedAreas={selectedAreas}
                    setSelectedAreas={setSelectedAreas}
                    resetKey={resetKey}
                    setResetKey={setResetKey}
                    ceabRow={ceabRow}
                    setCeabRow={setCeabRow}
                />
            ),
        },
        {
            key: "artsciTab",
            label: "Art Sci",
            children: (
                <ArtsciFilters
                    artsciEnabled={artsciEnabled}
                    setArtsciEnabled={setArtsciEnabled}
                    approvedOnly={approvedOnly}
                    setApprovedOnly={setApprovedOnly}
                    artsciSelectedTypes={artsciSelectedTypes}
                    setArtsciSelectedTypes={setArtsciSelectedTypes}
                    setResetKey={setResetKey}
                />
            ),
        },
    ];

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-end",
                ...style
            }}
        >
            <Popover
                trigger="click"
                placement="leftBottom"
                title={<span style={{ fontSize: "16px" }}>Filter Setting</span>}
                overlayStyle={{
                    width: `${filterPaneWidth}px`,
                }}
                content={
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "column",
                        }}
                    >
                        <Row
                            style={{
                                width: "100%",
                            }}
                        >
                            <Col span={6}>
                                <span>Terms:</span>
                            </Col>
                            <Col span={18} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <Select
                                    mode="multiple"
                                    allowClear
                                    style={{
                                        minWidth: '70%',
                                    }}
                                    size={'small'}
                                    placeholder="Select Terms"
                                    defaultValue={[
                                        {
                                            label: 'F',
                                            value: 'fall',
                                        },
                                        {
                                            label: 'S',
                                            value: 'winter',
                                        }
                                    ]}
                                    onChange={(values) => {
                                        console.log("Term change:", values);
                                        setSelectedTerms(values)
                                    }}
                                    options={termOptions}
                                />

                            </Col>
                        </Row>

                        <Row
                            style={{
                                width: "100%",
                                marginTop: '5px'
                            }}
                        >
                            <Col span={12}>
                                <span>Avoid Conflict:</span>
                            </Col>

                            <Col span={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <Switch
                                    checkedChildren="On"
                                    unCheckedChildren="Off"
                                    checked={avoidConflict}
                                    onChange={setAvoidConflict}
                                />
                            </Col>
                        </Row>

                        <Row
                            style={{
                                width: "100%",
                                marginTop: '5px'
                            }}
                        >
                            <Col span={18}>
                                <span>Num Return Courses:</span>
                            </Col>

                            <Col span={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <InputNumber
                                    min={1}
                                    max={10}
                                    size='small'
                                    value={numReturn}
                                    onChange={(e) => {
                                        setNumReturn(e);
                                    }}
                                />
                            </Col>
                        </Row>

                        <Tabs items={EngArtsciTabItems} />

                        <Divider 
                            style={{
                                marginTop: '10px',
                                marginBottom: '10px',
                            }}
                            
                        >CEAB Filters</Divider>

                        <div 
                            style={{
                                width: `${collapseWidth - 24}px`
                            }}
                        >
                            <CEABRequirementPanel
                                resetKey={resetKey}
                                ceabRow={ceabRow}
                                setCeabRow={setCeabRow}
                            />
                        </div>

                        <Button
                            onClick={() => {
                                setSelectedTypes(defaultEngSelectedTypes);
                                setSelectedAreas(defaultSelectedAreas);
                                setArtsciSelectedTypes(defaultArtsciSelectedTypes);
                                setResetKey((prevKey) => prevKey + 1);
                                setCeabRow([]);
                            }}
                            style={{
                                marginTop: "10px",
                                width: '100%',
                            }}
                            type="primary"
                            danger
                        >
                            Reset
                        </Button>
                    </div>
                }
            >
                <Button
                    style={{
                        width: '32px',
                        height: '32px',
                        marginRight: "5px",
                    }}
                    icon={<FilterOutlined />}
                />
            </Popover>

            {/* This component is intended to wrap around an input field to provide the filter data */}
            {React.Children.map(children, child =>
                React.cloneElement(child, { filterData })
            )}
        </div>
    );
};

export default FilterPanel;
export { CEABRequirementPanel };