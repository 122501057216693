import React, { useState } from "react";
import SplitPane from "react-split-pane";

import "./Stylesheets/App.css";

const MainSplitPanelsTwoSides = ({ left, right, minWidth, maxWidth, defaultWidth }) => {
    const [pane1Width, setPane1Width] = useState(defaultWidth);

    return (
        <SplitPane
            split="vertical"
            minSize={minWidth}
            step={5}
            defaultSize={defaultWidth}
            maxSize={maxWidth} 
            style={{ position: "relative", backgroundColor: '' }} 
            resizerClassName={"MajorResizer"}
            onResizerDoubleClick={(e) => {
                console.log('Resizer double click:', e)
                setPane1Width(defaultWidth);
            }}
            onChange={(e)=>{setPane1Width(e)}}
            pane1Style={{
                width: pane1Width
            }}
        >
            {left}
            {right}
        </SplitPane>
    );
};

export default MainSplitPanelsTwoSides;
