import React, { useState, useRef, useEffect, useMemo, useCallback } from "react";
import { Input, Button, Menu, Tooltip, Dropdown, Layout, theme, Space, message } from "antd";
import {
    AudioOutlined,
    CopyOutlined,
    EditOutlined,
    PauseOutlined,
    ReloadOutlined,
    CheckOutlined,
    CloseOutlined,
    DownCircleOutlined,
    RedoOutlined,
    SyncOutlined,
    MenuFoldOutlined,
    MenuUnfoldOutlined,
    RightOutlined,
    LeftOutlined,
    PlusCircleOutlined,
    EllipsisOutlined,
} from "@ant-design/icons";
import "../Stylesheets/App.css";
import { alphanumerical, sleep, server_api_addr, useRequestWithNavigate } from "../utils";
import { useAppContext } from "../App/AppContext";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";

const { TextArea } = Input;
const pfp_width = "70px";
const pfp_height = "70px";
const { Content, Sider } = Layout;

const CopyToClipboardButton = React.memo(({ content }) => {
    const [isCopied, setIsCopied] = useState(false);

    const handleCopy = useCallback(() => {
        navigator.clipboard
            .writeText(content)
            .then(() => {
                setIsCopied(true);
                setTimeout(() => setIsCopied(false), 2000);
            })
            .catch((err) => {
                console.error("Failed to copy text: ", err);
            });
    }, [content]);

    return (
        <Tooltip placement="bottom" arrow title={"Copy"}>
            <Button
                onClick={handleCopy}
                type="text"
                size="small"
                style={{ marginLeft: "5px" }}
                shape="circle"
                icon={isCopied ? <CheckOutlined /> : <CopyOutlined />}
            />
        </Tooltip>
    );
});

const defaultStyle = {
    cursor: "pointer",
    fontSize: "30px",
    marginRight: "10px",
    transition: "transform 0.175s ease",
};

const ChatSettingDropDownButton = React.memo(({ menuItems }) => {
    const [hovered, setHovered] = useState(false);
    const [dropDownOpen, setDropDownOpen] = useState(false);

    const handleHover = useCallback(
        async (e) => {
            setHovered(e);
            if (e) {
                await sleep(150);
            }
            setDropDownOpen(e);
        },
        [setHovered, setDropDownOpen]
    );

    return (
        <Dropdown
            menu={{ items: menuItems }}
            onOpenChange={handleHover}
            open={dropDownOpen}
            trigger="click"
            placement="top"
        >
            <DownCircleOutlined
                style={{
                    ...defaultStyle,
                    transform: hovered ? "rotate(-180deg)" : "rotate(-90deg)",
                }}
            />
        </Dropdown>
    );
});

const ChatNameRow = React.memo(({ item, switchCurConv, newChatSession }) => {
    const { activeProfile, setActiveProfile, save_item_to_server } = useAppContext();
    const [editName, setEditName] = useState(false);
    const [inputValue, setInputValue] = useState(item.name);
    const inputRef = useRef(null);
    const origName = useRef(item.name);

    const updateRowName = useCallback(() => {
        const tempProfile = { ...activeProfile };

        for (const conversation of tempProfile.chats) {
            if (conversation.key === item.key) {
                conversation.name = inputValue;
            }
        }

        setActiveProfile(tempProfile);

        // TODO: Get a new endpoint specifically responsible for this
        // save_item_to_server('update_profile', {profile: tempProfile}, true, "", 'Chat name successfully updated!', "");
    }, [activeProfile, item.key, inputValue, setActiveProfile]);

    useEffect(() => {
        if (inputRef.current) {
            if (editName) {
                inputRef.current.focus();
            } else {
                inputRef.current.blur();
            }
        }
    }, [editName]);

    return (
        <div
            style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
            }}
        >
            {!editName ? (
                <span
                    onClick={() => {
                        switchCurConv(item);
                    }}
                    style={{
                        display: "inline-block",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "clip",
                        verticalAlign: "top",
                        width: "90%",
                    }}
                >
                    {item.name}
                </span>
            ) : (
                <Input
                    ref={inputRef}
                    value={inputValue}
                    onChange={(e) => {
                        setInputValue(e.target.value);
                    }}
                    onKeyDown={(e) => {
                        if (e.code === "Enter") {
                            setEditName(false);
                            origName.current = inputValue;
                            updateRowName();
                        } else if (e.code === "Escape") {
                            setEditName(false);
                            setInputValue(origName.current);
                        }
                    }}
                    onBlur={() => {
                        setEditName(false);
                        updateRowName();
                    }}
                />
            )}

            <Dropdown
                trigger={"click"}
                menu={{
                    items: [
                        {
                            key: "renameConv",
                            label: "Rename",
                            onClick: () => {
                                setEditName(true);
                            },
                        },
                        {
                            key: "deleteConv",
                            label: (
                                <span
                                    style={{
                                        color: "red",
                                    }}
                                >
                                    Delete
                                </span>
                            ),
                            onClick: () => {
                                const tempProfile = { ...activeProfile };

                                if (item.key === newChatSession.current?.key) {
                                    newChatSession.current = null;
                                }
                                const newChats = tempProfile.chats.filter(
                                    (conversation) => conversation.key !== item.key
                                );
                                tempProfile.chats = newChats;

                                if(newChats.length === 0){
                                    message.error("Can't delete the last chat.")
                                } else {
                                    // TODO: Get a new endpoint specifically responsible for this
                                    // save_item_to_server('update_profile', {profile: tempProfile}, true, "", 'Chat successfully deleted!', "");
                                    setActiveProfile(tempProfile);
                                }
                            },
                        },
                    ],
                }}
            >
                <EllipsisOutlined className="ChatNameEllipse" />
            </Dropdown>
        </div>
    );
});

const SpeakModelResponseButton = React.memo(({ content }) => {
    const [isSpeaking, setIsSpeaking] = useState(false);
    const utteranceRef = useRef(null);

    const handleModelResponseReadButton = useCallback(() => {
        if (isSpeaking) {
            speechSynthesis.cancel();
            setIsSpeaking(false);
        } else {
            utteranceRef.current = new SpeechSynthesisUtterance(content);
            utteranceRef.current.onend = () => setIsSpeaking(false);
            speechSynthesis.speak(utteranceRef.current);
            setIsSpeaking(true);
        }
    }, [content, isSpeaking]);

    return (
        <Tooltip placement="bottom" arrow title={"Read"}>
            <Button onClick={handleModelResponseReadButton} type="text" size="small" shape="circle">
                <span
                    style={{
                        position: "absolute",
                        transition: "opacity 0.2s ease-out, transform 0.5s ease-out",
                        opacity: isSpeaking ? 1 : 0,
                        transform: isSpeaking ? "scale(1)" : "scale(0.8)",
                    }}
                >
                    <PauseOutlined />
                </span>
                <span
                    style={{
                        position: "absolute",
                        transition: "opacity 0.2s ease-out, transform 0.5s ease-out",
                        opacity: isSpeaking ? 0 : 1,
                        transform: isSpeaking ? "scale(0.8)" : "scale(1)",
                    }}
                >
                    <AudioOutlined />
                </span>
            </Button>
        </Tooltip>
    );
});

const ChatRowUser = React.memo(({ index, turn, chatHistory, setChatHistory }) => {
    const [displayEditButton, setDisplayEditButton] = useState(true);
    const [displayEditButtonHover, setDisplayEditButtonHover] = useState(false);

    const [userInputDisabled, setUserInputDisabled] = useState(true);
    const [currentDisplayText, setCurrentDisplayText] = useState(turn["content"]);

    const [originalInputText, setOriginalInputText] = useState(turn["content"]);

    const onUserRowHoverEnter = useCallback(() => {
        setDisplayEditButtonHover(true);
    }, []);

    const onUserRowHoverEnd = useCallback(() => {
        setDisplayEditButtonHover(false);
    }, []);

    const updateCurrentText = useCallback((e) => {
        setCurrentDisplayText(e.target.value);
    }, []);

    useEffect(() => {
        setCurrentDisplayText(turn["content"]);
    }, [turn]);

    const updateUserText = useCallback(() => {
        setDisplayEditButton(true);
        setUserInputDisabled(true);
        setOriginalInputText(currentDisplayText);

        const newChatHistory = [...chatHistory];

        newChatHistory[index]["content"] = currentDisplayText;

        setChatHistory(newChatHistory);
    }, [chatHistory, currentDisplayText, index, setChatHistory]);

    const handleCancelEdit = useCallback(() => {
        setDisplayEditButton(true);
        setUserInputDisabled(true);
        setCurrentDisplayText(originalInputText);
    }, [originalInputText]);

    return (
        <div
            key={index}
            className="ChatRow"
            onMouseOver={onUserRowHoverEnter}
            onMouseLeave={onUserRowHoverEnd}
            style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "flex-start",
                marginBottom: "10px",
                marginLeft: "25px",
            }}
        >
            <div
                style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    alignItems: "center",
                }}
            >
                <Button
                    onClick={() => {
                        setUserInputDisabled(false);
                        setDisplayEditButton(false);
                    }}
                    style={{
                        width: "32px",
                        height: "32px",
                        marginRight: "15px",
                        visibility: displayEditButtonHover && displayEditButton ? "visible" : "hidden",
                    }}
                    shape="circle"
                >
                    <EditOutlined />
                </Button>

                {userInputDisabled ? (
                    <div className="UserChatContent">
                        <p
                            style={{
                                whiteSpace: "pre-wrap",
                                display: userInputDisabled ? "" : "none",
                                marginTop: "0px",
                                marginBottom: "0px",
                            }}
                        >
                            {currentDisplayText}
                        </p>
                    </div>
                ) : (
                    <div
                        className="UserChatContent"
                        style={{
                            width: "100%",
                        }}
                    >
                        <TextArea
                            variant="borderless"
                            value={currentDisplayText}
                            style={{
                                width: "100%",
                                fontSize: "11pt",
                                color: "black",
                            }}
                            disabled={userInputDisabled}
                            onChange={updateCurrentText}
                            autoSize
                        />

                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "flex-end",
                            }}
                        >
                            <Button
                                onClick={handleCancelEdit}
                                style={{
                                    display: userInputDisabled ? "none" : "",
                                }}
                            >
                                Cancel
                            </Button>
                            <Button
                                onClick={updateUserText}
                                style={{
                                    display: userInputDisabled ? "none" : "",
                                    marginLeft: "15px",
                                }}
                                type="primary"
                            >
                                Okay
                            </Button>
                        </div>
                    </div>
                )}
            </div>

            <div
                style={{
                    width: pfp_width,
                    height: pfp_height,
                    backgroundImage: `url(${process.env.PUBLIC_URL}/spongebob.svg)`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    transform: "scaleX(-1)",
                }}
            />
        </div>
    );
});

const ChatRowAI = React.memo(({ index, turn }) => {
    const currentDisplayText = turn["content"];

    const handleModelResponseRegenerateButton = useCallback(() => {
        console.log("Regenerate");
    }, []);

    return (
        <div className="AIChatRow">
            <div
                style={{
                    width: pfp_width,
                    height: pfp_height,
                    backgroundImage: `url(${process.env.PUBLIC_URL}/smartgary.png)`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "top center",
                }}
            />
            <div
                style={{
                    width: "calc(90% - 100px)",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
            >
                <div style={{ marginLeft: "15px", width: "100%" }}>
                    <Markdown
                        remarkPlugins={[remarkGfm]}
                        components={{
                            code(props) {
                                const { children, className, node, ...rest } = props;
                                const match = /language-(\w+)/.exec(className || "");
                                return match ? (
                                    <SyntaxHighlighter
                                        {...rest}
                                        PreTag="div"
                                        children={String(children).replace(/\n$/, "")}
                                        language={match[1]}
                                    />
                                ) : (
                                    <code {...rest} className={className}>
                                        {children}
                                    </code>
                                );
                            },
                        }}
                    >
                        {currentDisplayText}
                    </Markdown>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-start",
                            marginTop: "15px",
                        }}
                    >
                        <SpeakModelResponseButton content={turn["content"]} />
                        <CopyToClipboardButton content={turn["content"]} />
                        <Tooltip placement="bottom" title={"Retry"}>
                            <Button
                                onClick={handleModelResponseRegenerateButton}
                                type="text"
                                size="small"
                                style={{ marginLeft: "5px" }}
                                shape="circle"
                                icon={<ReloadOutlined />}
                            />
                        </Tooltip>
                    </div>
                </div>
            </div>
        </div>
    );
});

function groupConversationsByDate(conversations) {
    const today = new Date();
    const oneDay = 24 * 60 * 60 * 1000;

    const grouped = {
        today: [],
        yesterday: [],
        "last 7 days": [],
        "last 30 days": [],
        earlier: [],
    };

    conversations.forEach((conversation) => {
        const lastChatDate = new Date(conversation["timestamp"]);
        const diffInDays = Math.floor((today - lastChatDate) / oneDay);

        if (diffInDays === 0) {
            grouped.today.push(conversation);
        } else if (diffInDays === 1) {
            grouped.yesterday.push(conversation);
        } else if (diffInDays <= 7) {
            grouped["last 7 days"].push(conversation);
        } else if (diffInDays <= 30) {
            grouped["last 30 days"].push(conversation);
        } else {
            grouped.earlier.push(conversation);
        }
    });

    // Sort conversations within each group by timestamp
    for (const key in grouped) {
        grouped[key].sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
    }

    return Object.entries(grouped).map(([label, sessions]) => ({ label, sessions }));
}

const ChatInterface = () => {
    const {
        draggingCard: draggingCourse,
        queryCourses,
        showServerSaveMessage,
        setQueryCourses,
        formattedCourseData,
        activeProfile,
        setActiveProfile,
        setFormattedCourseData,
        currentUserInfo,
    } = useAppContext();

    const rootRef = useRef(null);
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [currentChatValue, setCurrentChatValue] = useState("");
    const [groupedChatHistory, setGroupedChatHistory] = useState([]);
    const [menuSelectedKey, setMenuSelectedKey] = useState("");

    const [curConversation, setCurConversation] = useState([]);

    const newChatSession = useRef(null);

    const request = useRequestWithNavigate();

    const [chatHistoryCollapsed, setChatHistoryCollapsed] = useState(true);
    const [collapseButtonVisible, setCollapseButtonVisible] = useState(true);
    const historyBarWidth = 200;
    const refreshInterval = 100;

    const {
        token: { colorBgContainer },
    } = theme.useToken();

    // Auto-scrolling variables and functions
    const messagesStartRef = useRef(null);
    const messagesEndRef = useRef(null);
    const messagesContainerRef = useRef(null);
    const isAutoScrolling = useRef(false);

    const [isAtTop, setIsAtTop] = useState(false);
    const [isAtBottom, setIsAtBottom] = useState(false);
    const [userScrolled, setUserScrolled] = useState(false);
    const [isOverflowing, setIsOverflowing] = useState(false);

    const handleScroll = useCallback((e) => {
        const target = e.target;
        const bottom =
            Math.round(target.scrollHeight - target.scrollTop) ===
            Math.round(target.clientHeight);
        setIsAtBottom(bottom);

        const top = target.scrollTop === 0;
        setIsAtTop(top);

        if (!bottom && !isAutoScrolling.current) {
            setUserScrolled(true);
        } else {
            setUserScrolled(false);
        }

        const isOverflow = target.scrollHeight > target.clientHeight;
        setIsOverflowing(isOverflow);
    }, []);

    const scrollToBottom = useCallback(() => {
        isAutoScrolling.current = true;

        setTimeout(() => {
            if (messagesEndRef.current) {
                messagesEndRef.current.scrollIntoView({ behavior: "instant" });
            }

            isAutoScrolling.current = false;
        }, 100);
    }, []);

    useEffect(() => {
        if (isAtBottom || !userScrolled) {
            scrollToBottom();
        }
    }, [curConversation, isAtBottom, userScrolled, scrollToBottom]);

    useEffect(() => {
        scrollToBottom();
    }, [scrollToBottom]);

    const switchCurConv = useCallback(
        (item) => {
            setMenuSelectedKey(item.key);
            setCurConversation(item.history);
            setChatHistoryCollapsed(true);
        },
        []
    );

    useEffect(() => {
        if (activeProfile.chats) {
            const timeGroupChatHistory = groupConversationsByDate(
                activeProfile.chats
            );

            for (const group of timeGroupChatHistory) {
                let hist = group.sessions;
                if (hist.length === 0) {
                    continue;
                }
                setCurConversation(hist[0].history);
                break;
            }

            setGroupedChatHistory(timeGroupChatHistory);

            for (const timeGroup of timeGroupChatHistory) {
                let value = timeGroup["sessions"];

                if (value.length !== 0) {
                    setMenuSelectedKey(value[0].key);
                    break;
                }
            }
        }
    }, [activeProfile]);


    const getChatResponse = useCallback(
        async (newConversation) => {
            setButtonDisabled(true);

            const tokens = JSON.parse(localStorage.getItem('jwt'));
            const accessToken = tokens?.access;

            const chat_api = `${server_api_addr}chat/`;

            const response = await fetch(chat_api, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                },
                body: JSON.stringify({ chat_hist: newConversation }),
            });

            // const response = await fetch("http://localhost:5100/chat", {
            //     method: "POST",
            //     headers: {
            //         "Content-Type": "application/json",
            //     },
            //     body: JSON.stringify({ chat_hist: newConversation }),
            // });

            const reader = response.body.getReader();
            const decoder = new TextDecoder("utf-8");

            let assistant = { role: "assistant", content: "" };
            newConversation.push(assistant);

            let num_stream = 0;
            let done = false;
            while (!done) {
                const { value, done: streamDone } = await reader.read();
                done = streamDone;

                num_stream += 1;
                const chunk = decoder.decode(value, { stream: true });

                let splittedChunks = chunk.split("\n");

                for (const line of splittedChunks) {
                    await sleep(1)
                    if (line.startsWith("data:")) {
                        const jsonData = line.replace("data: ", "");
                        if (jsonData === "[DONE]") {
                            setButtonDisabled(false);
                        } else {
                            try {
                                const token = JSON.parse(jsonData).token;

                                assistant["content"] += token;

                                setCurConversation([...newConversation]);
                            } catch (e) {
                                console.error("There is an exception:", e, line);
                            }
                        }
                    }
                }
            }

            console.log("Stream done:", num_stream);
            // Delete new chat key
            if (menuSelectedKey === newChatSession.current?.key) {
                newChatSession.current = null;
            }

            let newAllChat = [];

            for (let chat of activeProfile.chats) {
                if (chat.key === menuSelectedKey) {
                    newAllChat.push({
                        ...chat,
                        history: newConversation,
                        timestamp: (new Date()).toISOString()
                    });
                } else {
                    newAllChat.push(chat);
                }
            }

            console.log('newAllChat: ', newAllChat)

            showServerSaveMessage.current = false;
            setActiveProfile(
                {
                    ...activeProfile,
                    chats: newAllChat
                }
            )
        },
        [menuSelectedKey]
    );

    const sendChat = useCallback(() => {
        if (buttonDisabled) {
            return;
        }

        if (currentChatValue.length === 0) {
            message.error("Please input to start chatting with the assistant.");
            return;
        }

        let newConversation = [
            ...curConversation,
            {
                role: "user",
                content: currentChatValue,
            },
        ];

        setCurConversation(newConversation);

        getChatResponse(newConversation);

        setButtonDisabled(true);
        setCurrentChatValue("");
    }, [buttonDisabled, currentChatValue, curConversation, getChatResponse]);

    const handleInputChange = useCallback((event) => {
        setCurrentChatValue(event.target.value);
    }, []);

    console.log("Chat Interface Rerender");

    const chatSettingDropdownItems = useMemo(
        () => [
            {
                key: "1",
                label: (
                    <Button
                        icon={chatHistoryCollapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                        onClick={() => {
                            setChatHistoryCollapsed(!chatHistoryCollapsed);
                        }}
                        style={{
                            width: '115px',
                            display: 'flex',
                            justifyContent: 'space-between'
                        }}
                    >
                        History
                    </Button>
                ),
            },
            {
                key: "2",
                label: (
                    <Button
                        icon={<RedoOutlined />}
                        onClick={() => {
                            startNewChat();
                        }}
                        style={{
                            width: '115px',
                            display: 'flex',
                            justifyContent: 'space-between'
                        }}
                    >
                        New Chat
                    </Button>
                ),
            }
        ],
        [chatHistoryCollapsed]
    );

    function getConv(key) {
        for (const chat of activeProfile.chats) {
            if (chat.key === key) {
                return chat;
            }
        }

        return null;
    }

    const startNewChat = useCallback(() => {
        console.log("New chat:")
        console.log(activeProfile)
        console.log(groupedChatHistory)
        const default_conv = [
            {
                role: "assistant",
                content: `Hi ${currentUserInfo.name}, I'm Dream Gary, how can I assist you today?`,
            },
        ];

        // If new chat key is set, then that means that the new chat is not used
        if (newChatSession.current !== null) {
            switchCurConv(newChatSession.current);
            return;
        }

        setCurConversation(default_conv);
        setChatHistoryCollapsed(true);

        const getTodayDate = () => {
            const today = new Date();
            const year = today.getFullYear();
            const month = String(today.getMonth() + 1).padStart(2, "0");
            const day = String(today.getDate()).padStart(2, "0");
            return `${year}-${month}-${day}`;
        };

        const cur_id = alphanumerical();
        const today = new Date();
        setMenuSelectedKey(cur_id);

        let newChatData = {
            key: cur_id,
            name: `Chat ${getTodayDate()}`,
            timestamp: today.toISOString(),
            history: default_conv,
        };

        newChatSession.current = newChatData;

        setActiveProfile({
            ...activeProfile,
            chats: [
                newChatData,
                ...activeProfile.chats
            ],
        });
    }, [activeProfile, setActiveProfile]);

    const [showQCArea, setShowQCArea] = useState(false);

    useEffect(() => {
        const interval = setInterval(() => {
            if (draggingCourse.current) {
                if (draggingCourse.current.source === "index") {
                    setShowQCArea(true);
                } else {
                    setShowQCArea(false);
                }
            } else {
                setShowQCArea(false);
            }
        }, refreshInterval);

        return () => clearInterval(interval);
    }, [draggingCourse]);

    const courseDropQC = useCallback(() => {
        let newQCArray = [
            ...queryCourses,
            formattedCourseData[draggingCourse.current.term_row_index].term_courses[
            draggingCourse.current.term_course_index
            ],
        ];
        let updatedQCArray = [...new Set(newQCArray)];
        setQueryCourses(updatedQCArray);

        if (updatedQCArray.length !== newQCArray.length) {
            message.error("The selected course is already included!");
        }

        draggingCourse.current = null;
    }, [draggingCourse, formattedCourseData, queryCourses, setQueryCourses]);

    return (
        <div
            style={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                backgroundColor: "rgb(250, 250, 250)",
            }}
            ref={rootRef}
        >
            <Layout style={{ width: "100%", height: "100%" }}>
                <Sider
                    style={{
                        background: colorBgContainer,
                        textAlign: "left",
                        left: chatHistoryCollapsed
                            ? `-${historyBarWidth}px`
                            : "0px",
                        overflow: "auto",
                    }}
                    width={chatHistoryCollapsed ? 0 : historyBarWidth}
                >
                    <div
                        className="AddNewChatButton"
                        onClick={() => {
                            startNewChat();
                        }}
                    >
                        <PlusCircleOutlined
                            style={{
                                fontSize: "16px",
                            }}
                        />
                        <span
                            style={{
                                marginLeft: "15px",
                                fontSize: "16px",
                                fontFamily: "Gill Sans, sans-serif",
                            }}
                        >
                            Start a new Chat
                        </span>
                    </div>
                    <Menu
                        mode="inline"
                        selectedKeys={menuSelectedKey}
                        items={[
                            {
                                type: "divider",
                                key: "divider",
                            },
                            ...groupedChatHistory
                                .filter((timeGroup) => timeGroup.sessions.length > 0)
                                .map((timeGroup) => ({
                                    key: `grp_${timeGroup.label}`,
                                    label: timeGroup.label,
                                    type: "group",
                                    children: timeGroup.sessions.map((item) => ({
                                        key: item.key,
                                        label: <ChatNameRow item={item} switchCurConv={switchCurConv} newChatSession={newChatSession} />,
                                    })),
                                })),
                        ]}
                    />
                </Sider>
                <Layout style={{ height: "100%" }}>
                    <Content
                        ref={messagesContainerRef}
                        onScroll={handleScroll}
                        style={{
                            height: "100%",
                            minWidth: "350px",
                            maxWidth: "100%",
                            overflowY: "auto",
                            paddingRight: "4px",
                            display: "flex",
                            flexDirection: "column",
                        }}
                    >
                        <div ref={messagesStartRef} />
                        <Button
                            style={{
                                position: "sticky",
                                top: "50%",
                                right: "100%",
                                width: "16px",
                                borderRadius: "4px",
                                opacity: collapseButtonVisible ? "100%" : "0%",
                            }}
                            onClick={() => {
                                setChatHistoryCollapsed(!chatHistoryCollapsed);
                            }}
                            onMouseEnter={() => {
                                setCollapseButtonVisible(true);
                            }}
                            icon={
                                chatHistoryCollapsed ? (
                                    <RightOutlined />
                                ) : (
                                    <LeftOutlined />
                                )
                            }
                        />
                        {curConversation.map((item, index) => {
                            const payload = {
                                index: index,
                                turn: item,
                                chatHistory: curConversation,
                                setChatHistory: setCurConversation,
                            };

                            if (item.role === "assistant") {
                                return (
                                    <ChatRowAI
                                        {...payload}
                                        key={`chatRow-${index}`}
                                    />
                                );
                            } else {
                                return (
                                    <ChatRowUser
                                        {...payload}
                                        key={`chatRow-${index}`}
                                    />
                                );
                            }
                        })}
                        <div ref={messagesEndRef} />
                    </Content>
                </Layout>
            </Layout>

            <div
                style={{
                    width: "95%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                }}
            >
                {showQCArea ? (
                    <div
                        className="QCDropArea"
                        onDragOver={(e) => e.preventDefault()}
                        onDrop={courseDropQC}
                    >
                        Drag Course Here to Start Asking Questions
                    </div>
                ) : (
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            marginBottom: "5px",
                        }}
                    >
                        {queryCourses.map((item, iterator) => (
                            <div key={`QC-${alphanumerical()}`}>
                                <CloseOutlined
                                    className="closeButton"
                                    onClick={() => {
                                        const newData = [...queryCourses];
                                        newData.splice(iterator, 1);
                                        setQueryCourses(newData);
                                    }}
                                />

                                <div
                                    style={{
                                        width: "130px",
                                        height: "60px",
                                        marginRight: "5px",
                                        color: item["status"] === 0 ? "black" : "#f90",
                                        cursor: "default",
                                    }}
                                    className="CourseCard"
                                >
                                    <span
                                        style={{
                                            fontSize: 14,
                                            fontFamily: "arial",
                                            textDecoration: "none",
                                            color: item["status"] === 0 ? "black" : "#f90",
                                            fontWeight: "bold",
                                        }}
                                        className="CourseCardFamily CourseCardCode"
                                    >
                                        {item["code"]}
                                    </span>

                                    <div
                                        style={{
                                            fontSize: "7pt",
                                            fontFamily: "arial",
                                            lineHeight: "1",
                                        }}
                                        className="CourseCardFamily CourseCardName"
                                    >
                                        {item["name"]}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                )}

                <Space.Compact style={{ width: "100%" }}>
                    <ChatSettingDropDownButton menuItems={chatSettingDropdownItems} />

                    <Input
                        placeholder="Ask me anything..."
                        value={currentChatValue}
                        onChange={handleInputChange}
                        onPressEnter={sendChat}
                        style={{ height: 40, borderRadius: "6px 0px 0px 6px" }}
                        key={"userInput"}
                        onDragOver={(e) => e.preventDefault()}
                        onDrop={courseDropQC}
                    />
                    <Button type="primary" onClick={sendChat} loading={buttonDisabled} style={{ height: 40 }}>
                        Send
                    </Button>
                </Space.Compact>
                Dream Gary can make mistake. Please check important information.
            </div>
        </div>
    );
};

export default ChatInterface;
