import React, { useState, useEffect, useRef } from "react";
import {
    Popover,
    Input,
    Spin,
    Divider,
} from "antd";
import {
    LoadingOutlined,
} from "@ant-design/icons";
import GenericCourseCardSmall from "./GenericCourseCard";
import { useRequestWithNavigate, processCourseDetails, alphanumerical } from "../utils";

const SearchedCourseList = ({ CourseSearchPayload, source = "index" }) => {
    // If CourseSearchPayload.courses is not empty, then it means there are courses matching the prefix
    // Then the AI suggested courses can be used
    // If CourseSearchPayload.courses is empty, then only display fuzzy searched courses.
    console.log(CourseSearchPayload);

    return CourseSearchPayload.courses && CourseSearchPayload.courses.length === 0 ? (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
            }}
        >
            <span>No course matched</span>
            <Divider
                style={{
                    marginTop: "4px",
                    marginBottom: "8px",
                }}
            >
                Do you mean:
            </Divider>
            {CourseSearchPayload.fuzzy.map((payload, i) => {
                // possible that no course matches the partial course code, need a way to indicate that, maybe N/A as the course code
                return (
                    <GenericCourseCardSmall
                        draggable
                        key={`ACC-Fuzzy-${i}`}
                        code={payload.code}
                        course_info={payload}
                        status={-1}
                        style={{ marginTop: "5px" }}
                        source={source}
                    ></GenericCourseCardSmall>
                );
            })}
        </div>
    ) : (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
            }}
        >
            {CourseSearchPayload.courses.map((payload, i) => {
                console.log(i, payload)
                let courseCode = Object.keys(payload)[0];
                let image_size = 26;

                // possible that no course matches the partial course code, need a way to indicate that, maybe N/A as the course code
                return (
                    <div
                        key={`coursesearch-${alphanumerical()}`}
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            marginBottom: '5px',
                        }}>
                        <GenericCourseCardSmall
                            draggable
                            key={`ACC-${i}`}
                            code={courseCode}
                            course_info={payload[courseCode]['payload']}
                            status={-1}
                            style={{ marginLeft: '0px' }}
                            source={source}
                        />
                        <div>
                            {
                                payload[courseCode]['fall'] && <img width={image_size} height={image_size} src="https://cdn-icons-png.flaticon.com/512/12705/12705539.png" />
                            }
                            {
                                payload[courseCode]['winter'] && <img width={image_size} height={image_size} src="https://cdn.icon-icons.com/icons2/3035/PNG/512/weather_snow_snowflake_winter_freeze_icon_189094.png" />
                            }
                            {
                                payload[courseCode]['summer'] && <img width={image_size} height={image_size} src="https://cdn-icons-png.flaticon.com/512/1812/1812960.png" />
                            }
                        </div>
                    </div>

                );
            })}
            <Divider
                style={{
                    marginTop: "8px",
                    marginBottom: "8px",
                }}
            />
            <div
                style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                }}
            >
                <div
                    style={{
                        width: "24px",
                        height: "24px",
                        backgroundImage:
                            'url("https://cdn-icons-png.flaticon.com/512/7334/7334113.png")',
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                    }}
                />
                <span
                    style={{
                        marginLeft: "5px",
                        fontSize: "16px",
                    }}
                >
                    Suggested:
                </span>
            </div>
            {CourseSearchPayload.AI.map((payload, i) => {
                // possible that no course matches the partial course code, need a way to indicate that, maybe N/A as the course code
                return (
                    <GenericCourseCardSmall
                        draggable
                        key={`AI-ACC-${i}`}
                        code={payload.code}
                        course_info={payload}
                        status={-1}
                        style={{ marginTop: "5px" }}
                        source={source}
                    ></GenericCourseCardSmall>
                );
            })}
        </div>
    );
};

const CourseSearchAutocomplete = ({
    style = {},
    zindex = 10,
    source = "index",
    filterData = {}
}) => {
    const [courseSearchValue, setCourseSearchValue] = useState("");
    const [autoCompleteShow, setAutoCompleteShow] = useState(false);
    const [autoCompleteCourses, setAutoCompleteCourses] = useState({});
    const [loading, setLoading] = useState(false);
    const searchValueRef = useRef("");
    const request = useRequestWithNavigate();

    console.log("Course search rerender", courseSearchValue);

    useEffect(() => {
        if(courseSearchValue.length >= 3){
            autocomplete(courseSearchValue);
        }
    }, [filterData])

    const requestCourseAutocompletion = async (partialCode) => {
        setLoading(true);
        setAutoCompleteShow(true);

        // This endpoint needs to take additional parameters, such as fall or winter, area, artsci
        return request("search_courses", {
            prefix: partialCode,
            ...filterData
        }).then((data) => {
            let returnData = {
                target: partialCode,
                courses: data,
                AI: [],
                fuzzy: [],
            };

            console.log("Got from server:", data);

            if (data.length === 0) {
                return returnData;
            }

            returnData.courses = data;

            return returnData;
        }).catch((e) => {
            console.log("Course Search Error:");
            console.log(e);
            return [];
        });
    };

    const autocomplete = (value) => {
        requestCourseAutocompletion(value).then((data) => {
            // Check if the result is for the current value, if not, drop it
            if (!data || !data.target) {
                setAutoCompleteCourses({});
                setAutoCompleteShow(false);
            }
            if (data.target.trim() === searchValueRef.current.trim()) {
                let newData = {
                    ...data
                }
                console.log(newData.courses);

                let tempCourses = [];

                let course_table = {};
                for (const course of newData.courses) {
                    const processedCourse = processCourseDetails(course);

                    if (!course_table[processedCourse.code]) {
                        course_table[processedCourse.code] = {
                            fall: processedCourse.fall,
                            winter: processedCourse.winter,
                            summer: processedCourse.summer,
                            payload: processedCourse
                        }
                    }
                }

                let course_num = 0;
                for (const [key, value] of Object.entries(course_table)) {
                    if (course_num > filterData.numReturn) {
                        break
                    }

                    tempCourses.push({
                        [key]: value
                    })
                }

                console.log(tempCourses);
                newData.courses = tempCourses;

                newData.courses = newData.courses.slice(0, filterData.numReturn);

                console.log(newData.courses);
                setAutoCompleteCourses(newData);
                setLoading(false);
            }
        });
    }

    // Debounced course search handler
    const handleCourseSearchRequest = (event) => {
        const value = event.target.value;
        setCourseSearchValue(value);
        searchValueRef.current = value;

        console.log("Current filter value:", filterData);

        // Only trigger the search if the input has 3 or more characters
        if (value.length >= 3) {
            autocomplete(value);
        } else {
            setAutoCompleteCourses({});
            setAutoCompleteShow(false);
        }
    };

    return (
        <Popover
            placement="rightTop"
            title={
                <Divider
                    style={{
                        marginTop: "4px",
                        marginBottom: "8px",
                    }}
                >
                    Available Courses:
                </Divider>
            }
            trigger="click"
            content={
                <div>
                    {loading ? (
                        <Spin indicator={<LoadingOutlined spin />} size={"large"} />
                    ) : (
                        <SearchedCourseList
                            source={source}
                            CourseSearchPayload={autoCompleteCourses}
                        />
                    )}
                </div>
            }
            onOpenChange={(e) => {
                if (e && courseSearchValue.length >= 3) {
                    setAutoCompleteShow(true);
                } else {
                    setAutoCompleteShow(false);
                }
            }}
            zIndex={zindex}
            overlayStyle={{
                width: '212px'
            }}
            open={autoCompleteShow}
        >
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-end",
                }}
            >
                <Input
                    placeholder="Search Course"
                    value={courseSearchValue}
                    onChange={handleCourseSearchRequest} // Main entry
                    style={{
                        minWidth: "150px",
                        ...style,
                    }}
                />
            </div>
        </Popover>
    );
};

export default CourseSearchAutocomplete;
