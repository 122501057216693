import { useContext } from 'react';
import { JwtContext } from './JWTProvider';
import { useLocation } from 'react-router-dom'; // React Router alternative

import { Button } from 'antd';

function generateState() {
	return Math.random().toString(36).substring(6);
}

let authRedirectUri = 'http://localhost:3000/auth/';

if(process.env.REACT_APP_PRODUCTION === "1"){
	authRedirectUri = process.env.REACT_APP_AUTH_REDIRECT_URI;
}

// const authRedirectUri = process.env.REACT_APP_AUTH_REDIRECT_URI || 'http://localhost:3000/auth';
// const authRedirectUri = 'http://localhost:3000/auth/'

const clientId = process.env.REACT_APP_LAFORGE_CLIENT_ID || '';

function LaForgeButton({nextPath='/dashboard'}) {
	const location = useLocation(); // React Router's hook to get current location
	const pathname = location.pathname;

	function handleClick() {
		const state = generateState();

		sessionStorage.setItem('next', nextPath);
		sessionStorage.setItem('state', state);

		const redirectUri = encodeURIComponent(authRedirectUri);

		const scope = 'read_user';
		window.location.href = `https://laforge.eecg.utoronto.ca/oauth/authorize?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=code&scope=${scope}&state=${state}`;
	}

	const buttonText = 'Login through UofT';

	return (
		<Button kind="primary" onClick={handleClick}>
			{buttonText}
		</Button>
	);
}

export default LaForgeButton;
