import React from 'react';
import { Checkbox, Row, Col, Tooltip, Divider } from 'antd';
import { alphanumerical } from '../utils';

const MultiCheckBox = ({ data, numCol = 2, checkall = false, value = [], onChange=null, key=null }) => {
    const colSpan = Math.floor(24 / numCol);

    // Extract all keys from data
    const allVals = data.map((entry) => entry.key);

    // Compute indeterminate and checkAll based on value
    const indeterminate = !!value.length && value.length < allVals.length;
    const checkAll = value.length === allVals.length;

    const onCheckAllChange = (e) => {
        const checked = e.target.checked;
        const newValue = checked ? allVals : [];
        onChange(newValue);
    };

    const onGroupChange = (list) => {
        onChange(list);
    };

    return (
        <div>
            {checkall && (
                <>
                    <Checkbox
                        indeterminate={indeterminate}
                        onChange={onCheckAllChange}
                        checked={checkAll}
                    >
                        Check All
                    </Checkbox>
                    <Divider
                        style={{
                            marginTop: '5px',
                            marginBottom: '5px',
                        }}
                    />
                </>
            )}
            <Checkbox.Group style={{ width: '100%' }} value={value} onChange={onGroupChange}>
                <Row>
                    {data.map((colData, idx) => (
                        <Col span={colSpan} key={`multiSelect-${key}-${idx}`}>
                            <Checkbox value={colData.key}>
                                <Tooltip
                                    title={colData.full}
                                    placement={idx % numCol ? 'right' : 'left'}
                                >
                                    {colData.label}
                                </Tooltip>
                            </Checkbox>
                        </Col>
                    ))}
                </Row>
            </Checkbox.Group>
        </div>
    );
};

export default MultiCheckBox;
