import GraduationChecker from "./GraduationChecklist";
import CourseTable from "./UserCourseList";
import { Divider } from "antd";

const DataVisualizer = ()=>{

    return (
        <>
            <CourseTable></CourseTable>
            <Divider
                style={{
                    marginTop: "4px",
                    marginBottom: "4px",
                }}
            ></Divider>
            <GraduationChecker></GraduationChecker>
        </>
    )
}

export default DataVisualizer;
