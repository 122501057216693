import React, { useState, useEffect, useRef } from "react";
import "../Stylesheets/index.css";
import { Modal, Popover, Input, Flex, Menu, Tooltip, message } from "antd";
import {
    BookOutlined,
    DeleteOutlined,
    ProfileOutlined,
    SearchOutlined,
    UserOutlined,
} from "@ant-design/icons";
import { dc, findCourseLocation, processCourseDetails, requestCourseDetails, termToChar, termToStr, useRequestWithNavigate } from "../utils";
import useCourseActions from "./courseActions";
import { useAppContext } from "../App/AppContext";
import { defaultFilterData } from "../utils";

function handleAskAssistant(record, target, queryCourses, setQueryCourses) {
    console.log("Ask Assistant");
    console.log(record, target, queryCourses);

    for (var course of record.term_courses) {
        if (course["code"] === target) {
            if (queryCourses) {
                setQueryCourses([...new Set([...queryCourses, course])]);
            } else {
                setQueryCourses([course]);
            }
        }
    }
}


const OnEmptyPopup = ({ record, target, visible, x, y, setState, rootRef }) => {
    const { formattedCourseData, setFormattedCourseData,
        courseAddModalOpen,
        setCourseAddModalOpen,
        courseAddModalInfo,
        setCourseAddModalInfo,
    } = useAppContext();

    const { addTerm, deleteTerm, manipulateCourseList } = useCourseActions();
    const request = useRequestWithNavigate();

    function getTermsBetween(startTerm, endTerm) {
        const termsInYear = [1, 5, 9]; // Possible term codes in a year
        let result = [];

        if (startTerm === null && endTerm !== null) {
            // If startTerm is null, return the two terms before the endTerm
            let endYear = Math.floor(endTerm / 10);
            let endTermCode = endTerm % 10;

            // Find the two terms before the endTerm
            for (let i = termsInYear.length - 1; i >= 0; i--) {
                if (termsInYear[i] < endTermCode) {
                    result.push(`${endYear}${termsInYear[i]}`);
                }
            }

            // Add previous year's terms if necessary
            if (result.length < 2) {
                endYear -= 1;
                result.unshift(`${endYear}${termsInYear[2]}`, `${endYear}${termsInYear[1]}`);
            }

            return result.slice(0, 2); // Return at most 2 terms
        }

        if (endTerm === null && startTerm !== null) {
            // If endTerm is null, return the two terms after the startTerm
            let startYear = Math.floor(startTerm / 10);
            let startTermCode = startTerm % 10;

            // Find the two terms after the startTerm
            for (let i = 0; i < termsInYear.length; i++) {
                if (termsInYear[i] > startTermCode) {
                    result.push(`${startYear}${termsInYear[i]}`);
                }
            }

            // Add next year's terms if necessary
            if (result.length < 2) {
                startYear += 1;
                result.push(`${startYear}${termsInYear[0]}`, `${startYear}${termsInYear[1]}`);
            }

            return result.slice(0, 2); // Return at most 2 terms
        }

        if (startTerm !== null && endTerm !== null) {
            // Both startTerm and endTerm are provided, return terms strictly between
            let startYear = Math.floor(startTerm / 10);
            let startTermCode = startTerm % 10;
            let endYear = Math.floor(endTerm / 10);
            let endTermCode = endTerm % 10;

            // Generate all terms from startYear to endYear
            for (let year = startYear; year <= endYear; year++) {
                for (let termCode of termsInYear) {
                    // Skip the start term
                    if (year === startYear && termCode <= startTermCode) continue;

                    // Skip the end term
                    if (year === endYear && termCode >= endTermCode) break;

                    // Add valid terms in between
                    result.push(`${year}${termCode}`);
                }
            }

            return result;
        }

        // If both startTerm and endTerm are null (should not happen), return an empty array
        return result;
    }

    function handleAddCourse() {
        console.log("Add Course");
        setCourseAddModalOpen(true);
        setCourseAddModalInfo({
            term: record.term_name
        });
    }

    function handleDeleteRowCourses() {
        console.log("Delete row", formattedCourseData);

        for (let i = 0; i < formattedCourseData.length; i++) {
            if (formattedCourseData[i].term_name === record.term_name) {
                deleteTerm(i, record);
                break;
            }
        }
    }

    let addBeforeOptions = [

    ]

    let addAfterOptions = [

    ];

    if (formattedCourseData && record) {
        console.log(record, formattedCourseData);

        let lastTerm = null;
        let nextTerm = null;
        let i = 0;

        for (; i < formattedCourseData.length; i++) {
            if (formattedCourseData[i].term_name === record.term_name) {
                if (i !== 0) {
                    lastTerm = formattedCourseData[i - 1];
                }
                nextTerm = formattedCourseData[i + 1];
                break
            }
        }

        let beforeTerms = [];
        let afterTerms = [];
        if (lastTerm) {
            beforeTerms = getTermsBetween(lastTerm.term_name, record.term_name);
        } else {
            beforeTerms = getTermsBetween(null, record.term_name);
        }

        if (nextTerm) {
            afterTerms = getTermsBetween(record.term_name, nextTerm.term_name);
        } else {
            afterTerms = getTermsBetween(record.term_name, null);
        }

        for (const availableTerm of beforeTerms) {
            addBeforeOptions.push({
                key: `addBefore-${availableTerm}`,
                label: availableTerm,
                onClick: (e) => {
                    e.domEvent.stopPropagation();

                    addTerm(availableTerm, i);

                    setState((prev)=>{
                        return {
                            ...prev,
                            visible: false
                        }
                    })
                }
            })
        }

        for (const availableTerm of afterTerms) {
            addAfterOptions.push({
                key: `addAfter-${availableTerm}`,
                label: availableTerm,
                onClick: (e) => {
                    e.domEvent.stopPropagation();

                    addTerm(availableTerm, i + 1);

                    setState((prev)=>{
                        return {
                            ...prev,
                            visible: false
                        }
                    })
                }
            })
        }

    }

    let addNewTermMenu = [];

    if (addBeforeOptions.length !== 0) {
        addNewTermMenu.push({
            key: 'addTermBefore',
            label: 'Add Before',
            children: addBeforeOptions
        });
    }

    if (addAfterOptions.length !== 0) {
        addNewTermMenu.push({
            key: 'addTermAfter',
            label: 'Add After',
            children: addAfterOptions
        })
    }

    async function testCourseManipulation() {
        const actions = ['swap', 'move', 'delete', 'add'];
        let action = actions[Math.round(Math.random() * 3)];

        let term_start = 4;

        let first_course_term = term_start + Math.floor(Math.random() * (formattedCourseData.length - term_start));
        let second_course_term = term_start + Math.floor(Math.random() * (formattedCourseData.length - term_start));
        
        let first_course_col = Math.floor(Math.random() * formattedCourseData[first_course_term].term_courses.length);
        let second_course_col = Math.floor(Math.random() * formattedCourseData[second_course_term].term_courses.length);

        let first_course = formattedCourseData[first_course_term].term_courses[first_course_col];
        let second_course = formattedCourseData[second_course_term].term_courses[second_course_col];

        if (first_course_term === second_course_term && first_course_col === second_course_col) {
            message.info("Cannot swap the same course, testing delete instead");
            action = 'delete';
        }

        action = 'move';

        if(action === 'swap'){
            manipulateCourseList(rootRef,{
                action: "swap",
                source_course: {
                    code: first_course.code,
                    term: first_course.term,
                },
                dest_course: {
                    code: second_course.code,
                    term: second_course.term,
                },
            });
        } else if (action === 'move') {
            console.log("================================ Move start ================================");
            console.log("Moving", first_course, "to", second_course.term);
            manipulateCourseList(rootRef,{
                action: "move",
                source_course: {
                    code: first_course.code,
                    term: first_course.term,
                },
                dest_course: {
                    term: first_course.term,
                },
            });
            console.log("================================ Move end ================================");
        } else if (action === 'delete') {
            manipulateCourseList(rootRef,{
                action: "delete",
                course: {
                    code: first_course.code,
                    term: first_course.term,
                },
            });
        } else if (action === 'add') {
            // search a random course with search_courses of first_course.term and add it to second_course.term
            let course_to_be_added = null;
            let filter = dc(defaultFilterData);

            filter['semester'] = [termToStr(first_course.term)];

            await request("search_courses", {
                prefix: 'ece',
                ...filter
            }).then((course_data) => {
                if(course_data.length === 0){
                    message.error("Requested course does not exist.")
                    return;
                }
                let data = course_data[Math.round(Math.random() * course_data.length)];
                console.log("Got data", course_data);
                let course_details = processCourseDetails(data);
                console.log("Got course detail:", course_details);

                course_to_be_added = course_details;
            })

            manipulateCourseList(rootRef,{
                action: "add",
                source_course: course_to_be_added,
                dest_course: {
                    row_index: first_course_term,
                    dest_course: first_course_col,
                },
            });
        }
    }

    return (
        visible && (
            <ul
                className="popup"
                style={{ position: "fixed", left: `${x}px`, top: `${y}px`, zIndex: 10, width: '140px' }}
            >
                <li onClick={handleAddCourse}>
                    <BookOutlined />
                    &nbsp; Add Course
                </li>
                <li 
                    className={addBeforeOptions.length === 0 && addAfterOptions.length === 0 ? 'disabled' : ""} 
                >
                    <Popover
                        placement="rightTop"
                        overlayInnerStyle={{
                            padding: 0,
                        }}
                        content={
                            <Menu
                                items={addNewTermMenu}
                                mode='vertical'
                            />
                        }
                    >
                        <div>
                            <ProfileOutlined></ProfileOutlined>
                            &nbsp; Add Term
                        </div>
                    </Popover>
                </li>
                <li onClick={handleAskAssistant}>
                    <UserOutlined />
                    &nbsp; Ask Assistant
                </li>
                <li onClick={handleDeleteRowCourses} style={{ color: 'red' }}>
                    <DeleteOutlined />
                    &nbsp; Delete Term
                </li>
                <li onClick={testCourseManipulation} style={{ color: 'red' }}>
                    <DeleteOutlined />
                    &nbsp; Course Manip
                </li>
            </ul>
        )
    )
};

const OnCardPopup = ({ record, target, visible, x, y, queryCourses, setQueryCourses, setCourseList }) => {
    const [modalOpen, setModelOpen] = useState(false);
    const [currentCourse, setCurrentCourse] = useState({});
    const courseLocation = useRef([-1, -1]);

    const { deleteCourse } = useCourseActions();
    const { setCurrentCourseDetails, setCourseInfoModalOpen, formattedCourseData } = useAppContext();
    const request = useRequestWithNavigate();

    function showCourseInfoModal(record, target) {
        // code, section, year
        console.log(record, target)
        const course_details = requestCourseDetails(target, record.term_name);

        course_details.then(data => {
            if (data) {
                setCurrentCourseDetails(data);
            }
        })
        setCourseInfoModalOpen(true);
    }

    function handleDeleteCourse(record, target) {
        console.log("Delete Course", record, target);

        const [term_row_index, term_course_index] = findCourseLocation(formattedCourseData, record.term_name, target);

        deleteCourse(term_row_index, term_course_index)
    }

    function handleInputChange(key, newValue) {
        setCurrentCourse((prevCourse) => ({
            ...prevCourse,
            [key]: newValue,
        }));
    }

    function handleEditCourse(record, target) {
        console.log("Edit Course");

        for (let course of record.term_courses) {
            if (course["code"] === target) {
                setCurrentCourse(course);
                setModelOpen(true);
                break;
            }
        }
    }

    const handleModalOK = () => {
        setCourseList((prevState) => {
            const newData = [...prevState];
            const course = newData[courseLocation.current[0]].term_courses[courseLocation.current[1]];

            for (const [key, value] of Object.entries(currentCourse)) {
                if (typeof course[key] === "number") {
                    course[key] = Number(value);
                } else {
                    course[key] = value;
                }
            }

            return newData;
        });

        setModelOpen(false);
    };

    useEffect(() => {
        if (record !== null) {
            setCourseList((prevState) => {
                let term_row_index = 0;
                for (; term_row_index < prevState.length; term_row_index++) {
                    if (prevState[term_row_index]["term_name"] === record.term_name) {
                        break;
                    }
                }

                let term_course_index = 0;
                for (; term_course_index < prevState[term_row_index]["term_courses"].length; term_course_index++) {
                    if (prevState[term_row_index]["term_courses"][term_course_index]["code"] === currentCourse.code) {
                        break;
                    }
                }

                courseLocation.current = [term_row_index, term_course_index];
                return prevState;
            });
        }
    }, [currentCourse, record, setCourseList]);

    return (
        <div>
            <Modal
                title="Course Debug Menu"
                open={modalOpen}
                onOk={handleModalOK}
                onCancel={() => setModelOpen(false)}
                width={750}
                style={{
                    top: "5%",
                }}
            >
                {Object.entries(currentCourse).map(([key, value]) => (
                    <Flex key={key} style={{ marginTop: "5px" }}>
                        <span style={{ width: "20%" }}>{key}:</span>
                        <Input
                            style={{ marginLeft: "10px", width: "80%" }}
                            value={value}
                            onChange={(event) => handleInputChange(key, event.target.value)}
                        />
                    </Flex>
                ))}
            </Modal>

            {visible && (
                <ul className="popup" style={{ position: "fixed", left: `${x}px`, top: `${y}px`, zIndex: 10 }}>
                    <li
                        onClick={() => {
                            showCourseInfoModal(record, target);
                        }}
                    >
                        <SearchOutlined />
                        &nbsp; Course Details
                    </li>
                    {
                        queryCourses !== null && setQueryCourses !== null && (
                            <li
                                onClick={() => {
                                    handleAskAssistant(record, target, queryCourses, setQueryCourses);
                                }}
                            >
                                <UserOutlined />
                                &nbsp; Ask Assistant
                            </li>
                        )
                    }
                    <li
                        onClick={() => {
                            handleDeleteCourse(record, target);
                        }}
                        style={{ color: "red" }}
                    >
                        <DeleteOutlined />
                        &nbsp; Delete Course
                    </li>
                    {/* <li
                        onClick={() => {
                            testFunction(record, target);
                        }}
                        style={{ color: "red" }}
                    >
                        <DeleteOutlined />
                        &nbsp; [DEBUG] Test Button
                    </li> */}
                    <li
                        onClick={() => {
                            handleEditCourse(record, target);
                        }}
                        style={{ color: "red" }}
                    >
                        <DeleteOutlined />
                        &nbsp; [DEBUG] Edit Course
                    </li>
                </ul>
            )}
            <a id="downloadAnchorElem" style={{display: 'none'}}></a>
        </div>
    );
};

export { OnEmptyPopup, OnCardPopup };
