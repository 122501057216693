import { useEffect, useState } from "react";
import { useNavigate, useSearchParams, Link, json } from "react-router-dom"; // Import Link here
import axios from "axios";
import { sendAuthRequest } from "../utils";

const HandleLaforgeLogin = ({ setAuthenticated, setTargetUser }) => {
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    useEffect(() => {
        const code = searchParams.get('code');
        const state = searchParams.get('state');
        const storedState = sessionStorage.getItem('state'); // CSRF protection)

        if (!code || !state) {
            setError("Missing authentication parameters.");
            return;
        }

        if (state !== storedState) {
            setError("State mismatch. Potential CSRF detected.");
            return;
        }

        const authenticate = async () => {
            try {
                // Send the `code` to the backend to exchange it for a JWT,
                const response = await axios.post('https://smartgellan.compeng.gg/api/auth_user/', {
                    code
                }, {
                    withCredentials: true, // Ensures cookies or session are included in future requests
                });

                // const response = await sendAuthRequest({'code': code});

                console.log('Got from server', response.data)

                localStorage.setItem('jwt', {
                    'access': response.data.access,
                    'refresh': response.data.refresh
                }); 

                if (response.data.utorid) {
                    console.log("Auth successfull, login with utorid:", response.data.utorid);
                    // Assuming the JWT is returned in response.data.jwt
                    localStorage.setItem('jwt', JSON.stringify({
                        'access': response.data.access,
                        'refresh': response.data.refresh
                    })); // Save the JWT
                    setTargetUser(response.data.utorid);
                    setAuthenticated(true);
                    navigate(sessionStorage.getItem('next')); // Redirect to next page
                } else {
                    setError("Authentication failed.");
                }
            } catch (err) {
                setError("An unexpected error occurred during authentication.");
                let pageCode = err.response?.data;
                if(typeof(pageCode) === 'object'){
                    pageCode = JSON.stringify(pageCode)
                }

                navigate('/error', { state: { htmlCode: pageCode || "<h1>Server Error</h1><p>An error occurred.</p>" } });
                // console.error(err);
                // console.log(err.response?.data);
            }
        };
        
        authenticate();

        // Clean up session storage
        return () => {
            sessionStorage.removeItem('state');
        };
    }, [searchParams, setAuthenticated, navigate]);

    if (error) {
        return (
            <div>
                <h1>Authentication Error</h1>
                <p>{error}</p>
                <Link to="/">Return to Login</Link> {/* Link to home page */}
            </div>
        );
    }

    return <></>;
};

export default HandleLaforgeLogin;
