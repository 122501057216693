// src/AppContext.js

import { createContext, useContext, useState, useEffect, useRef } from "react";
import { format_course_data_source, useRequestWithNavigate, sleep, groupByTerm, unformatProfileCourses } from "../utils";
import default_conversation from "./default_conversation.json"
import { message } from "antd";

// Create a context
const AppContext = createContext();


// Create a provider component
export const AppProvider = ({ children, target_user, admin = false }) => {
    const [currentUserInfo, setCurrentUserInfo] = useState({
        student_number: null,
        name: "",
        email: "",
    });
    
    const request = useRequestWithNavigate();

    const [messageApi, messageContextHolder] = message.useMessage();

    const [allProfiles, setAllProfiles] = useState([]);
    const [activeProfile, setActiveProfile] = useState({});
    
    const [queryCourses, setQueryCourses] = useState([]);

    const [formattedCourseData, setFormattedCourseData] = useState([]);

    const [courseAddModalOpen, setCourseAddModalOpen] = useState(false);
    const [courseAddModalInfo, setCourseAddModalInfo] = useState({});

    const [courseInfoModalOpen, setCourseInfoModalOpen] = useState(false);
    const [currentCourseDetails, setCurrentCourseDetails] = useState({
        name: "Operating Systems",
        code: "ECE344H1",
        description:
            "Operating system structures, concurrency, synchronization, deadlock, CPU scheduling, memory management, file systems. The laboratory exercises will require implementation of part of an operating system.",
        // This could be ECE244H1 and/or ECE243H1, need a way to distinguish
        prerequisites: ["ECE244H1", "ECE243H1"],
        corequisites: [],
        exclusions: ["ECE353H1 S"],

        creditWeight: 0.5,

        // 1, 2, 3, 4, 5, 6, 7(Science and Math)
        area: 6,

        // Kernel, Depth, HSS, CS, Free, None
        type: "Kernel",
        fall: true,
        winter: true,
        offered: "2022-2023",
        delivery: [3, 0, 30],
        au_dist: [0, 0, 0, 60, 40],
        ceab: [0, 0, 0, 32, 21.4],

        // Not Taken, Passed, Failed, In Progress, Planned
        status: "Not Taken",
    });

    const [chatHistory, setChatHistory] = useState(default_conversation);

    // Draggable Course Card State
    const draggingCard = useRef(null);

    const showServerSaveMessage = useRef(false);

    const UpdateProfileLoadingMessage = async (newProfile) => {
        // Open a loading message

        if(showServerSaveMessage.current){
            messageApi.open({
                key: "updateSave",
                type: 'loading',
                content: 'Saving updated profile to server...',
            });
        }

        await request('update_profile', {
            profile: newProfile
        }).then((response)=>{
            console.log("Server responded:", response);
            if(showServerSaveMessage.current){
                messageApi.open({
                    key: "updateSave",
                    type: 'success',
                    content: 'Updated profile saved to server successfully!',
                    duration: 2,
                });
            }
        }).catch((error)=>{
            console.error('Server responded with error:', error)
            if(showServerSaveMessage.current){
                messageApi.open({
                    key: "updateSave",
                    type: 'error',
                    content: 'Something went wrong, failed to save profile to server.',
                    duration: 2,
                });
            }
        })

        showServerSaveMessage.current = true;
    }

    const save_item_to_server = async (endpoint, payload, feedback=true, im="", sm="", fm="") => {
        console.log("save_item_to_server called", endpoint, payload, im, sm, fm);
        if(feedback){
            messageApi.open({
                key: "updateSave",
                type: 'loading',
                content: im !== "" ? im : 'Saving to server...',
            });
        }

        request(endpoint, payload).then((data) => {
            console.log("Item saved successfully to server, server responded:", data);
            if(feedback){
                messageApi.open({
                    key: "updateSave",
                    type: 'success',
                    content: sm !== "" ? sm : 'Action saved to server successfully',
                    duration: 2,
                });
            }
        }).catch((error) => {
            console.error("Error ouccured when saving item to server, error:", error);
            if(feedback){
                messageApi.open({
                    key: "updateSave",
                    type: 'error',
                    content: fm !== "" ? fm : 'Something went wrong, failed to save action to server',
                    duration: 2,
                });
            }
        })
    }

    function formatUserInfo(data) {
        console.log("Formatuserinfo:", data);
        setCurrentUserInfo({
            name: data.name,
            email: data.email,
            utorid: data.utorid
        });

        let temp_all_profiles = [];
        let profile_set = false;
        for (const profile of data["profiles"]) {
            
            const profileInfo = {
                ...profile,
                courses: format_course_data_source(groupByTerm(profile.courses))
            };

            console.log("Profile:", profileInfo)

            temp_all_profiles.push(profileInfo)

            if (profile['is_default'] === 1) {
                profile_set = true;
                setActiveProfile(profileInfo);
                setFormattedCourseData(profileInfo.courses);
            }
        }

        if(!profile_set){
            setActiveProfile(temp_all_profiles[0]);
            setFormattedCourseData(temp_all_profiles[0].courses);
        }

        console.log("After formatting user data")
        console.log(temp_all_profiles)
        setAllProfiles(temp_all_profiles);
    }

    // Fetch user info and courses when the app loads
    useEffect(() => {
        if (!admin) {
            const load_dummy = false;

            if (load_dummy) {
                fetch("/dummy_data.json")
                    .then((response) => response.json())
                    .then((data) => {
                        // Update current user data after fetch
                        formatUserInfo(data);
                    })
                    .catch((error) => console.error("Error fetching the JSON data:", error));
            } else {
                
                request('get_user_info', {
                    utorid: target_user,
                }).then(data => {
                    if (data) {
                        console.log('User data:', data);
                        formatUserInfo(data);
                    }
                })
            }
        } else {
            const year = (new Date()).getFullYear();
            const month = (new Date()).getMonth();

            let term = 1;

            if(5 <= month && month < 9){
                term = 5;
            } else if (9 <= month){
                term = 9;
            }

            setFormattedCourseData([
                {
                    key: `${year}${term}`,
                    term_name: Number(`${year}${term}`),
                    term_courses: []
                }
            ]);
        }
    }, []);

    // Making sure that the courses are sync'd up with the active profile
    // I don't know if this is necessary, it's deleted for now to fix state conflicts
    // useEffect(()=>{
    //     if(Object.keys(activeProfile) !== 0){
    //         let update = {
    //             ...activeProfile,
    //             courses: formattedCourseData
    //         }
    //         setActiveProfile(update);
    //     }
    // }, [formattedCourseData])

    return (
        <AppContext.Provider
            value={{
                currentUserInfo,

                formattedCourseData,
                setFormattedCourseData,

                currentCourseDetails,
                setCurrentCourseDetails,

                activeProfile,
                setActiveProfile,
                allProfiles,
                setAllProfiles,

                courseInfoModalOpen,
                setCourseInfoModalOpen,

                queryCourses,
                setQueryCourses,
                chatHistory,
                setChatHistory,

                messageContextHolder,

                draggingCard,
                showServerSaveMessage,

                courseAddModalOpen, 
                setCourseAddModalOpen,
                courseAddModalInfo, 
                setCourseAddModalInfo,

                save_item_to_server,
            }}
        >
            {children}
        </AppContext.Provider>
    );
};

// Custom hook to use context values
export const useAppContext = () => {
    return useContext(AppContext);
};
