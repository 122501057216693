import { useState, useEffect } from "react";
import MainSplitPanelsTwoSides from "../resizablePanels.js";
import ChatInterface from "../ChatInterface/chatInterface.js";
import "../Stylesheets/App.css";
import { Space, Layout, Dropdown, Typography, Button, Tooltip, message, Slider } from "antd";
import {
    AuditOutlined,
    BookOutlined,
    BugOutlined,
    CheckSquareOutlined,
    DownOutlined,
    FileTextOutlined,
    PlusOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "./AppContext.js";
import CourseDetailsModal from "../CourseDetailsModal.js";
import DataVisualizer from "../DataVisualizer/DataVisualizer.js";
import { alphanumerical, useRequestWithNavigate, groupByTerm, format_course_data_source, unformatProfileCourses } from "../utils.js";
import { ProfileRowSmall, ProfileDisplay } from "./ProfileSubComponents.js";
import CourseAddModal from "../AddTermCourseModal.js";

const { Header, Content } = Layout;
let required_courses = [
    {
        'code': 'APS100H1 F',
        'name': 'Orientation to Engineering',
        'term': 20199,
        'status': 0,
        'area': 'O',
        'type': 'R',
        'twin': 0,
        'ceab': [0, 0, 1920, 0, 0]
    },
    {
        'code': 'APS110H1 F',
        'name': 'Engineering Chemistry and Materials Science',
        'term': 20199,
        'status': 0,
        'area': 'O',
        'type': 'R',
        'twin': 0,
        'ceab': [0, 3840, 0, 1280, 0]
    },
    {
        'code': 'APS111H1 F',
        'name': 'Engineering Strategies & Practice I',
        'term': 20199,
        'status': 0,
        'area': 'O',
        'type': 'R',
        'twin': 0,
        'ceab': [0, 0, 3070, 0, 2050]
    },
    {
        'code': 'CIV100H1 F',
        'name': 'Mechanics',
        'term': 20199,
        'status': 0,
        'area': 'O',
        'type': 'R',
        'twin': 0,
        'ceab': [0, 3579, 0, 1540, 0]
    },
    {
        'code': 'MAT186H1 F',
        'name': 'Calculus I',
        'term': 20199,
        'status': 0,
        'area': 'O',
        'type': 'R',
        'twin': 0,
        'ceab': [4480, 0, 0, 0, 0]
    },
    {
        'code': 'MAT188H1 F',
        'name': 'Linear Algebra',
        'term': 20199,
        'status': 0,
        'area': 'O',
        'type': 'R',
        'twin': 0,
        'ceab': [5120, 0, 0, 0, 0]
    },
    {
        'code': 'APS105H1 S',
        'name': 'Computer Fundamentals',
        'term': 20201,
        'status': 0,
        'area': 'O',
        'type': 'R',
        'twin': 0,
        'ceab': [0, 0, 0, 5760, 0]
    },
    {
        'code': 'APS112H1 S',
        'name': 'Engineering Strategies & Practice II',
        'term': 20201,
        'status': 0,
        'area': 'O',
        'type': 'R',
        'twin': 0,
        'ceab': [0, 0, 1540, 0, 2300]
    },
    {
        'code': 'ECE110H1 S',
        'name': 'Electrical Fundamentals',
        'term': 20201,
        'status': 0,
        'area': 'O',
        'type': 'R',
        'twin': 0,
        'ceab': [0, 2880, 0, 2880, 0]
    },
    {
        'code': 'ECE191H1 S',
        'name': 'Introduction to Electrical and Computer Engineering',
        'term': 20201,
        'status': 0,
        'area': 'O',
        'type': 'R',
        'twin': 0,
        'ceab': [0, 0, 0, 1280, 0]
    },
    {
        'code': 'MAT187H1 S',
        'name': 'Calculus II',
        'term': 20201,
        'status': 0,
        'area': 'O',
        'type': 'R',
        'twin': 0,
        'ceab': [4480, 0, 0, 0, 0]
    },
    {
        'code': 'MIE100H1 S',
        'name': 'Dynamics',
        'term': 20201,
        'status': 0,
        'area': 'O',
        'type': 'R',
        'twin': 0,
        'ceab': [0, 3579, 0, 1540, 0]
    },
    {
        'code': 'ECE201H1 F',
        'name': 'Seminar Course: Electrical and Computer Engineering',
        'term': 20209,
        'status': 0,
        'area': 'O',
        'type': 'R',
        'twin': 0,
        'ceab': [0, 0, 0, 1280, 0]
    },
    {
        'code': 'ECE212H1 F',
        'name': 'Circuit Analysis',
        'term': 20209,
        'status': 0,
        'area': 'O',
        'type': 'R',
        'twin': 0,
        'ceab': [0, 0, 0, 5940, 0]
    },
    {
        'code': 'ECE241H1 F',
        'name': 'Digital Systems',
        'term': 20209,
        'status': 0,
        'area': 'O',
        'type': 'R',
        'twin': 0,
        'ceab': [0, 0, 0, 2670, 2670]
    },
    {
        'code': 'ECE244H1 F',
        'name': 'Programming Fundamentals',
        'term': 20209,
        'status': 0,
        'area': 'O',
        'type': 'R',
        'twin': 0,
        'ceab': [0, 0, 0, 4230, 1410]
    },
    {
        'code': 'MAT290H1 F',
        'name': 'Advanced Engineering Mathematics',
        'term': 20209,
        'status': 0,
        'area': 'O',
        'type': 'R',
        'twin': 0,
        'ceab': [5040, 0, 0, 0, 0]
    },
    {
        'code': 'MAT291H1 F',
        'name': 'Calculus III',
        'term': 20209,
        'status': 0,
        'area': 'O',
        'type': 'R',
        'twin': 0,
        'ceab': [5040, 0, 0, 0, 0]
    },
    {
        'code': 'ECE216H1 S',
        'name': 'Signals and Systems',
        'term': 20211,
        'status': 0,
        'area': 'O',
        'type': 'R',
        'twin': 0,
        'ceab': [1410, 0, 0, 4230, 0]
    },
    {
        'code': 'ECE221H1 S',
        'name': 'Electric and Magnetic Fields',
        'term': 20211,
        'status': 0,
        'area': 'O',
        'type': 'R',
        'twin': 0,
        'ceab': [0, 4230, 0, 1410, 0]
    },
    {
        'code': 'ECE231H1 S',
        'name': 'Introductory Electronics',
        'term': 20211,
        'status': 0,
        'area': 'O',
        'type': 'R',
        'twin': 0,
        'ceab': [0, 0, 0, 4460, 1490]
    },
    {
        'code': 'ECE243H1 S',
        'name': 'Computer Organization',
        'term': 20211,
        'status': 0,
        'area': 'O',
        'type': 'R',
        'twin': 0,
        'ceab': [0, 0, 0, 3740, 1600]
    },
    {
        'code': 'ECE297H1 S',
        'name': 'Communication and Design',
        'term': 20211,
        'status': 0,
        'area': 'O',
        'type': 'R',
        'twin': 0,
        'ceab': [0, 0, 2480, 0, 2480]
    }
]

function Profile({ setAuthenticated }) {
    const {
        messageContextHolder,
        activeProfile,
        setActiveProfile,
        allProfiles,
        draggingCard,
        setAllProfiles,
        currentUserInfo,
        setFormattedCourseData,
        formattedCourseData,
    } = useAppContext();

    const chatInterfaceMinWidth = 450;
    const theoreticalLeftMaxWidth = window.visualViewport.width - chatInterfaceMinWidth;
    const [profileSelectionOpen, setProfileSelectionOpen] = useState(false);

    const [courseListMaxWidth, setCourseListMaxWidth] = useState(100);
    const displayCourseCardWidth = 130;
    const courseListMinWidth = 3 * displayCourseCardWidth + 100;
    let courseListDefaultWidth = Math.min(theoreticalLeftMaxWidth, 6 * (displayCourseCardWidth + 4) + 80);
    const navigate = useNavigate();
    const request = useRequestWithNavigate();

    function signOut() {
        navigate("/");
        setAuthenticated(false);
        localStorage.setItem('jwt', JSON.stringify({}));
        console.log("Signed out.");
    }

    useEffect(() => {
        var maxCourseCount = 1;
        for (var term of formattedCourseData) {
            if (term.term_courses.length > maxCourseCount) {
                maxCourseCount = term.term_courses.length;
            }
        }

        courseListDefaultWidth = Math.min(maxCourseCount * (displayCourseCardWidth + 4) + 80, theoreticalLeftMaxWidth);
        const maxWidth = Math.min((maxCourseCount + 2) * displayCourseCardWidth + 100, theoreticalLeftMaxWidth);

        console.log((maxCourseCount + 2) * displayCourseCardWidth + 100, theoreticalLeftMaxWidth);
        setCourseListMaxWidth(maxWidth);
    }, [formattedCourseData]);

    const userInfoItems = [
        {
            label: (
                <p className="UserInfoRow">
                    <strong>Student Email: </strong> {currentUserInfo.email}
                </p>
            ),
            key: "1",
            style: {
                cursor: "default",
            },
        },
        // {
        //     label: (
        //         <p className="UserInfoRow">
        //             <strong>Degree Post: </strong>
        //             <span>{currentUserInfo.degree}</span>
        //         </p>
        //     ),
        //     key: "3",
        //     type: "info",
        //     style: {
        //         cursor: "default",
        //     },
        // },
        {
            type: "divider",
        },
        {
            label: "Sign Out",
            onClick: signOut,
            key: "4",
        },
    ];

    function switchCurrentProfile(profileInfo) {
        console.log("Switch profile", profileInfo);
        if (profileInfo.key !== activeProfile.key) {
            // Send profile update to server so that is_default flag is updated
            let newAllProfiles = [];
            for(const profile of allProfiles){
                if(profile.key === profileInfo.key){
                    const newActiveProfile = {
                        ...profileInfo,
                        is_default: 1
                    };

                    newAllProfiles.push(newActiveProfile);
                    setActiveProfile(newActiveProfile);
                } else if (profile.key === activeProfile.key){
                    newAllProfiles.push({
                        ...activeProfile,
                        is_default: 0
                    });
                } else {
                    newAllProfiles.push(profile);
                }
            }

            setAllProfiles(newAllProfiles);
            message.success(`Successfully switch to profile: ${profileInfo.name}`);
        } else {
            message.warning("Already in the selected profile!");
        }
    }

    function addNewProfile() {
        let newProfile = {
            'key': alphanumerical(),
            'name': 'New Profile',
            'ece': '',
            'status': 0,
            'details': [],
            'is_default': 0,
            'chats': [
                {
                    'key': alphanumerical(), 
                    'name': 'Default Conversation', 
                    'timestamp': (new Date()).toISOString(), 
                    "history": [
                        {
                            "role": "assistant",
                            "content": "Hi there, I'm **Dream Gary**. How can I assist you today?"
                        }
                    ], 
                }
            ],
            'courses': required_courses
        }

        let newProfilePayload = {
            utorid: currentUserInfo.utorid,
            profile: newProfile
        };

        request('add_profile', newProfilePayload).then((data)=>{
            message.success('New profile added successfully!');
            // Format courses
            newProfile.courses = format_course_data_source(groupByTerm(newProfile.courses));
            // switchCurrentProfile(newProfile);
            setAllProfiles([
                ...allProfiles,
                newProfile
            ])
        }).catch((e)=>{
            message.error("Failed to add a new profile");
            console.log('server error:', e);
        });
    }

    const profileSelectorItems = [
        {
            key: "ActiveProfileGroup",
            type: "group",
            label: <span>Active Profile</span>,
            children: [
                {
                    // label: activeProfile.name,
                    label: <ProfileRowSmall profileInfo={activeProfile} />,
                    onClick: (e) => {
                        // console.log('Click profile:', e);
                        switchCurrentProfile(activeProfile);
                    },
                    key: `ActiveProfile-${activeProfile.name}`,
                },
            ],
        },
        {
            type: "divider",
        },
        {
            key: "OtherProfileGroup",
            type: "group",
            label: <span>Other Profiles</span>,
            children: (
                allProfiles.length > 1 ? (allProfiles.map((profile) => {
                    if (profile.key !== activeProfile.key) {
                        return {
                            label: <ProfileRowSmall profileInfo={profile} />,
                            key: `other-profile-${profile.key}`,
                            onClick: (e) => {
                                // console.log('Click profile:', e);
                                switchCurrentProfile(profile);
                            },
                        };
                    }
                })) : (
                    [{
                        label: (
                            <div style={{
                                textAlign: 'center'
                            }}>
                                There are no other profiles, click the button below to create a new profile
                            </div>
                        ),
                        key: `no_other_profile`,
                        onClick: addNewProfile
                    }]
                )
            )
        },
        {
            type: "divider",
        },
        {
            label: (
                <div style={{
                    paddingLeft: '8px'
                }}>
                    <PlusOutlined style={{
                        fontSize: '16px'
                    }}></PlusOutlined>
                    <span style={{
                        marginLeft: '8px',
                        fontSize: '16px',
                        fontWeight: 'bold'
                    }}>Add a new profile</span>
                </div>

            ),
            key: `add-new-profile`,
            onClick: (e) => {
                addNewProfile();
            }
        },
    ];

    return (
        <div
            className="App"
            style={{ padding: "0px" }}
            onClick={(e) => {
                console.log('Click on app:', e);
                setProfileSelectionOpen(false);
            }}
            onDragEnd={(e) => {
                console.log(e);
                draggingCard.current = null;
            }}
        >
            {messageContextHolder}

            <CourseDetailsModal />
            <CourseAddModal />

            <Layout>
                <Header className="AppHeader">
                    <Dropdown
                        open={profileSelectionOpen}
                        menu={{
                            items: profileSelectorItems,
                            onClick: (info) => {
                                console.log("Click on row:", info);
                            },
                            onMouseLeave: () => {
                                // setProfileSelectionOpen(false);
                            },
                        }}
                        trigger="click"
                        onOpenChange={(e) => {
                            if (e) {
                                setProfileSelectionOpen(e);
                            }
                        }}
                        overlayStyle={{
                            width: "325px",
                        }}
                    >
                        <div
                            onClick={(e) => {
                                e.stopPropagation(); // Prevent the event from bubbling up to the parent
                            }}
                        >
                            <ProfileDisplay activeProfile={activeProfile}>{activeProfile.name}</ProfileDisplay>
                        </div>
                    </Dropdown>

                    {/* <Slider 
                        onChange={(e)=>{
                            setActiveProfile({
                                ...activeProfile,
                                status: e
                            })
                        }}
                        defaultValue={50}
                        style={{
                            width: '250px'
                        }}
                        min={0}
                        max={100}
                    /> */}

                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <Tooltip placement="bottom" arrow title={"Request a New Feature"}>
                            <Button
                                shape="circle"
                                style={{ marginRight: "10px" }}
                                icon={<AuditOutlined />}
                                children={""}
                                onClick={() => {
                                    window.open('https://www.ece.utoronto.ca/wp-content/uploads/2017/11/Planning-to-graduate-in-June.pdf',
                                        '_blank', 'noopener,noreferrer')
                                }}
                            />
                        </Tooltip>

                        <Tooltip placement="bottom" arrow title={"Allowed CS Courses"}>
                            <Button
                                shape="circle"
                                icon={<BookOutlined />}
                                style={{ marginRight: "10px" }}
                                children={""}
                                onClick={() => {
                                    window.open('https://undergrad.engineering.utoronto.ca/academics-registration/electives/complementary-studies-cs-electives/',
                                        '_blank', 'noopener,noreferrer')
                                }}
                            />
                        </Tooltip>

                        <Tooltip placement="bottom" arrow title={"Allowed HSS Courses"}>
                            <Button
                                shape="circle"
                                icon={<BookOutlined />}
                                style={{ marginRight: "10px" }}
                                children={""}
                                onClick={() => {
                                    window.open('https://portal.engineering.utoronto.ca/sites/hss/hss-approved-list.html',
                                        '_blank', 'noopener,noreferrer')
                                }}
                            />
                        </Tooltip>

                        <Tooltip placement="bottom" arrow title={"Report a Bug"}>
                            <Button
                                shape="circle"
                                icon={<BugOutlined />}
                                style={{ marginRight: "10px" }}
                                children={""}
                                onClick={() => {
                                    window.open('https://www.ece.utoronto.ca/wp-content/uploads/2017/11/Planning-to-graduate-in-June.pdf',
                                        '_blank', 'noopener,noreferrer')
                                }}
                            />
                        </Tooltip>

                        <Tooltip placement="bottom" arrow title={"Requirements"}>
                            <Button
                                shape="circle"
                                style={{ marginRight: "10px" }}
                                icon={<CheckSquareOutlined />}
                                children={""}
                                onClick={() => {
                                    window.open('https://www.ece.utoronto.ca/wp-content/uploads/2017/11/Planning-to-graduate-in-June.pdf',
                                        '_blank', 'noopener,noreferrer')
                                }}
                            />
                        </Tooltip>

                        <Tooltip placement="bottom" arrow title={"Resources"}>
                            <Button
                                shape="circle"
                                style={{ marginRight: "10px" }}
                                icon={<FileTextOutlined />}
                                children={""}
                                onClick={() => {
                                    window.open(
                                        'https://undergrad.engineering.utoronto.ca/academics-registration/academic-calendar/',
                                        '_blank',
                                        'noopener,noreferrer'
                                    )
                                }}
                            />
                        </Tooltip>

                        <div
                            className="UserAvatar"
                            style={{
                                marginRight: "10px",
                                backgroundImage: `url("https://i.pinimg.com/originals/65/7c/54/657c546962f70ece78cae4227cc45600.jpg")`,
                                backgroundSize: "cover",
                                backgroundRepeat: "no-repeat",
                                backgroundPosition: "center",
                            }}
                        />
                        <Dropdown
                            menu={{
                                items: userInfoItems,
                            }}
                            trigger={["click"]}
                            placement="bottomRight"
                            arrow
                            style={{ width: "500px" }}
                        >
                            <Space>
                                <strong style={{ cursor: "pointer" }}>{currentUserInfo.name}</strong>
                                <DownOutlined style={{ cursor: "pointer" }} />
                            </Space>
                        </Dropdown>
                    </div>
                </Header>
                <Content style={{ height: "calc(100vh - 77px)" }}>
                    <MainSplitPanelsTwoSides
                        minWidth={courseListMinWidth}
                        maxWidth={courseListMaxWidth}
                        defaultWidth={courseListDefaultWidth}
                        left={
                            // <CourseTable/>
                            <DataVisualizer />
                        }
                        right={<ChatInterface />}
                    />
                </Content>
            </Layout>
        </div>
    );
}

export default Profile;
