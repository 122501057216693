import ReactJson from 'react-json-view';
import { Input, Button, Flex, Form, AutoComplete, message, Upload, Divider, Space } from "antd";
import { useEffect, useState } from "react";
import { InboxOutlined, SearchOutlined } from "@ant-design/icons";
import axios from "axios";
import { Schedule } from "../DataVisualizer/UserCourseList"
import { useAppContext } from "../App/AppContext";
import { format_course_data_source, useRequestWithNavigate, alphanumerical, server_api_addr, groupByTerm, useRequestWithNavigateJWT } from "../utils";

const defaultChatHistory = [
    {
        "role": "assistant",
        "content": "Hi there, I'm **Dream Gary**. How can I assist you today?"
    }
]

const InputButtonAction = ({ action }) => {
    const [value, setValue] = useState('');

    return (
        <Input value={value} onChange={setValue} addonAfter={
            <SearchOutlined onClick={() => {
                action();
            }} />
        } />
    )
}

const AdminAddProfileCourse = () => {
    const [form] = Form.useForm(); // Initialize the form instance
    const request = useRequestWithNavigate();
    const [targetUserUtorid, setTargetUserUtorid] = useState(null);
    const [targetUserData, setTargetUserData] = useState(null);
    const [targetUserSchedule, setTargetUserSchedule] = useState([]);

    const [profileKey, setProfileKey] = useState('');
    const [profileKeysList, setProfileKeysList] = useState([]);

    const handle_add_profile_course = ({ utorid, profileName, schedule }) => {
        // console.log(e)
        console.log(`Add profile ${profileName} to ${utorid}`);
        console.log(schedule);

        let all_courses = [];

        for(let term of schedule){
            all_courses.push(...term.term_courses)
        }

        request('add_profile_course', {
            key: '',
            courses: all_courses
        }).then((response) => {
            if (response) {
                message.success("Success!")
            } else {
                message.error("Operation failed!")
            }
        })
    };

    function requestUserData(){
        console.log("Target:", targetUserUtorid);
        request('get_user_info', {
            utorid: targetUserUtorid,
        }).then(data => {
            if (data) {
                console.log('User data: ', data);

                let temp_all_profile_keys = [];
                for(const profile of data.profiles){
                    temp_all_profile_keys.push({
                        value: profile.key
                    });
                }
                
                setTargetUserSchedule([]);
                console.log('All profile keys: ', temp_all_profile_keys);
                setProfileKeysList(temp_all_profile_keys);
                setTargetUserData(data);
                console.log("All done");
            }
        })
    }

    useEffect(()=>{
        console.log('Effect: ', targetUserData)
        if(targetUserData){
            console.log("Got targer user data")
            for(const profile of targetUserData.profiles){
                if(profile.key === profileKey){
                    setTargetUserSchedule(format_course_data_source(groupByTerm(profile.courses)));
                    break;
                }
            }
        }
    }, [profileKey, targetUserData])

    console.log('?', profileKey, targetUserData, targetUserSchedule);

    return (
        <>
            (
                <Space.Compact>
                    <Input placeholder="Enter the profile key" onChange={(e)=>{setTargetUserUtorid(e.target.value)}} />
                    <Button onClick={() => {
                        requestUserData();
                    }}>Submit</Button>
                </Space.Compact>
            )

            {
                targetUserData && <AutoComplete
                    value={profileKey}
                    options={profileKeysList}
                    style={{ width: 300 }}
                    onChange={(val)=>{setProfileKey(val)}}
                    placeholder="Enter profile key"
                    filterOption={(inputValue, option) =>
                        option.value.toLowerCase().includes(inputValue.toLowerCase())
                    }
                    notFoundContent={null}
                />
            }

            {
                targetUserData && (
                    <Form
                        form={form}
                        name="utoridForm"
                        layout="vertical"
                        onFinish={(e) => {
                            handle_add_profile_course(e);
                        }}
                    >
                        <Form.Item
                            name={'courses'}
                        >
                            <Schedule formattedCourseData={targetUserSchedule} setFormattedCourseData={setTargetUserSchedule} />
                        </Form.Item>

                        {/* Submit Button */}
                        <Form.Item>
                            <Button type="primary" htmlType="submit">
                                Submit
                            </Button>
                        </Form.Item>
                    </Form>
                )
            }
        </>
    );
}

const AdminAddUser = () => {
    const { formattedCourseData, setFormattedCourseData, queryCourses, setQueryCourses } = useAppContext();

    const payload = {
        formattedCourseData,
        setFormattedCourseData,
        queryCourses,
        setQueryCourses,
    }

    const [userFileList, setUserFileList] = useState([]);
    const defaultProfileKey = alphanumerical();
    const defaultUser = {
        'name': 'Wenbo Wang',
        'student_number': '1007113008',
        'email': 'wenbofred.wang@mail.utoronto.ca',
        'alter_email': 'juicyradish@gmail.com',
        'gender': 'M',
        'utorid': 'wangw362',
        'profile': [{
            "key": defaultProfileKey,
            "name": "Default Profile",
            "is_default": 1,
            "program": "",
            "status": 0,
            "details": [],
            "chats": [
                {
                    "key": alphanumerical(),
                    "name": "Default Conversation",
                    "timestamp": (new Date()).toISOString(),
                    "history": defaultChatHistory
                }
            ],
            "courses": []
        }]
    }

    const [dummyUserInfo, setDummyUserInfo] = useState();

    const handleUserFileChange = (info) => {
        console.log(info);
        setUserFileList(info.fileList);

        var newFileList = [];
        for (const file of info.fileList) {
            newFileList = [...newFileList, file.originFileObj]
        }
        setUserFileList(newFileList);
    };

    const userUploadProps = {
        name: "file",
        multiple: true,
        headers: {
            authorization: "authorization-text",
        },
        onChange: handleUserFileChange,
        beforeUpload: () => {
            return false;
        }
    };

    function uploadUserFile() {
        let targetUserInfo = {};

        if (userFileList.length === 0) {
            message.error("No file selected!");
            return;
        }

        for (const file of userFileList) {
            const reader = new FileReader();

            reader.onload = e => {
                targetUserInfo = JSON.parse(e.target.result);

                console.log(targetUserInfo);
                let response = handle_add_user(targetUserInfo);

                response.then((result) => {
                    if (result) {
                        message.success('User created!');
                    } else {
                        message.error('Operation failed!');
                    }
                });
            };
            reader.readAsText(file);
        }
    }

    function handleInputChange(key, newValue) {
        setDummyUserInfo(prevCourse => ({
            ...prevCourse,
            [key]: newValue
        }));
    }

    const handle_add_user = async (payload) => {
        console.log(payload);
        try {
            const response = await axios.post(server_api_addr, {
                request: 'add_user',
                payload: payload
            });
            console.log(response.data);
            alert(JSON.stringify(response.data));
            return true;
        } catch (error) {
            if (error.response) {
                console.log(error.response.data);
                alert(JSON.stringify(error.response.data));
            } else {
                console.error('Error', error);
            }
            return false;
        }
    };

    return (
        <div>
            {Object.entries(dummyUserInfo).map(([key, value]) => {
                if (key !== 'profile') {
                    return (<Flex align="center" justify="left" style={{ marginTop: "5px" }} key={alphanumerical()}>
                        <span style={{ width: '10%' }}>{key}:</span>
                        <Input
                            value={value}
                            onChange={(event) => handleInputChange(key, event.target.value)}
                        />
                    </Flex>
                    )
                }
            }
            )}

            <Schedule {...payload} onChange={setFormattedCourseData} />

            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', marginTop: '15px' }}>
                <Button type="primary" onClick={() => {
                    console.log(dummyUserInfo);
                    let targetUserInfo = {
                        ...dummyUserInfo
                    }

                    targetUserInfo.profile[0].courses = formattedCourseData;

                    console.log("Create the following user:")
                    console.log(targetUserInfo);
                    return false;
                    let response = handle_add_user(targetUserInfo);
                    response.then((result) => {
                        if (result) {
                            message.success('User created!');
                        } else {
                            message.error('Operation failed!');
                        }
                    });
                }}>Upload User Manually</Button>
            </div>

            <Divider>OR Upload User File Directly</Divider>

            <Upload.Dragger {...userUploadProps}>
                <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                </p>
                <p className="ant-upload-text">Click or drag user files to this area to upload</p>
                <p className="ant-upload-hint">Support for a single user file or bulk upload. Legal users will be created.</p>
            </Upload.Dragger>

            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', marginTop: '15px' }}>
                <Button type="primary" onClick={() => {
                    uploadUserFile();
                }}>Upload User with File</Button>
            </div>
        </div>
    )
}

const AdminAddProfile = () => {
    const [form] = Form.useForm(); // Initialize the form instance
    const request = useRequestWithNavigate();

    const { formattedCourseData, setFormattedCourseData, queryCourses, setQueryCourses } = useAppContext();

    const payload = {
        formattedCourseData,
        setFormattedCourseData,
        queryCourses,
        setQueryCourses,
    }

    const handle_add_profile = ({ utorid, profileName, schedule }) => {
        // console.log(e)
        console.log(`Add profile ${profileName} to ${utorid}`);
        console.log(schedule);
        return false;
        request('add_profile', {
            utorid: utorid,
            key: alphanumerical(),
            name: profileName,
            degree: 'AECPEBASC',
            ece: '',
            status: '0',
            details: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'],
            chats: [
                {
                    "key": alphanumerical(),
                    "name": "Default Conversation",
                    "timestamp": (new Date()).toISOString(),
                    "history": defaultChatHistory
                }
            ],
            courses: []
        }).then((response) => {
            if (response) {
                message.success("Success!")
            } else {
                message.error("Operation failed!")
            }
        })
    };

    return (
        <Form
            form={form}
            name="utoridForm"
            layout="vertical"
            onFinish={(e) => {
                handle_add_profile(e);
            }}
        >
            {/* UTORid Field */}
            <Form.Item
                label="UTORid"
                name="utorid"
                rules={[{ required: true, message: 'Please enter your UTORid!' }]}
            >
                <Input placeholder="Enter your UTORid" />
            </Form.Item>

            {/* Profile Name Field */}
            <Form.Item
                label="Profile Name"
                name="profileName"
                rules={[{ required: true, message: 'Please enter a profile name!' }]}
            >
                <Input placeholder="Enter a profile name" />
            </Form.Item>

            <Form.Item
                name={'courses'}
            >
                <Schedule {...payload} />
            </Form.Item>

            {/* Submit Button */}
            <Form.Item>
                <Button type="primary" htmlType="submit">
                    Submit
                </Button>
            </Form.Item>
        </Form>
    );
}

function CommandRunner() {
    const request = useRequestWithNavigate();
    const [commandName, setCommandName] = useState('');
    const [jsonPayload, setJsonPayload] = useState({});
    const [options, setOptions] = useState([
        { value: 'auth_user' },
        { value: 'update_profile_courses' },
        { value: 'get_user_info' },
        { value: 'delete_user' },
        { value: 'add_profile' },
        { value: 'delete_profile' },
        { value: 'add_profile_course' },
        { value: 'move_profile_course' },
        { value: 'delete_profile_course' },
        { value: 'get_course' },
        { value: 'search_courses' },
        { value: 'search_area_courses' },
        { value: 'add_eng_course' },
        { value: 'add_artsci_course' },
        { value: 'delete_all_users' },
        { value: 'delete_all_courses' }
    ]);
    

    const handleCommandNameChange = (value) => {
        setCommandName(value);
    };

    const handleJsonChange = (edit) => {
        setJsonPayload(edit.updated_src);
    };

    const handleSubmit = () => {
        console.log('Command Name:', commandName);
        console.log('JSON Payload:', jsonPayload);
        
        // Handle the submit action here
        request(commandName, jsonPayload).then((data)=>{
            console.log("Result result:", data);
        })
    };

    return (
        <div>
            <div style={{ marginBottom: '10px' }}>
                <label>
                    <strong>Command Name:</strong>
                </label>
                <br />
                <AutoComplete
                    value={commandName}
                    options={options}
                    style={{ width: 300 }}
                    onChange={handleCommandNameChange}
                    placeholder="Enter command name"
                    filterOption={(inputValue, option) =>
                        option.value.toLowerCase().includes(inputValue.toLowerCase())
                    }
                    notFoundContent={null}
                />
            </div>
            <div>
                <label>
                    <strong>JSON Payload:</strong>
                </label>
                <ReactJson
                    src={jsonPayload}
                    onEdit={handleJsonChange}
                    onAdd={handleJsonChange}
                    onDelete={handleJsonChange}
                    style={{ padding: '10px', backgroundColor: '#f5f5f5' }}
                    theme="rjv-default"
                    enableClipboard={false}
                />
            </div>
            <Button type="primary" onClick={handleSubmit} style={{ marginTop: '20px' }}>
                Submit
            </Button>
        </div>
    );
}


export { CommandRunner, InputButtonAction, AdminAddUser, AdminAddProfile, AdminAddProfileCourse };