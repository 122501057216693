import { message } from "antd";
import { useContext } from "react";
import { useAppContext } from "../App/AppContext";
import { findCourseLocation, genericCourse2UserCourse, termToStr, termToChar, sleep, useRequestWithNavigate, dc } from "../utils";
import { CourseListContext } from "./CourseListContext";

const useCourseActions = () => {
    const { formattedCourseData, setFormattedCourseData, draggingCard, activeProfile, save_item_to_server } = useAppContext();

    const {courseActionStep, courseActionHistory} = useContext(CourseListContext);

    const request = useRequestWithNavigate();

    const setCourseActionHistory = (newData) => {
        courseActionHistory.current = [...newData];
    };

    const updateProfileCourses = (newData)=>{
        let temp_courses = [];

        for(const term of newData){
            for(const course of term.term_courses){
                temp_courses.push(genericCourse2UserCourse(course, Number(term.term_name)))
            }
        }

        const updatedProfile = {
            ...activeProfile,
            courses: temp_courses,
            is_default: 1
        };
        
        // Here, just send a profile update request
        // Might be better if there is a dedicated endpoint for updating courses
        save_item_to_server('update_profile', {profile: updatedProfile}, true, "", 'Term successfully deleted!', "");
    }

    const addActionToHistory = (action) => {
        console.log('addActionToHistory:', courseActionStep, courseActionHistory)
        if (courseActionStep.current !== courseActionHistory.current.length) {
            const newData = courseActionHistory.current.slice(0, courseActionStep.current);
            newData.push(action);
            setCourseActionHistory(newData);
        } else {
            setCourseActionHistory([...courseActionHistory.current, action]);
        }

        courseActionStep.current += 1;
    }

    const addTerm = (availableTerm, i, courses = [], record = true) => {
        const newData = [...formattedCourseData];

        newData.splice(i, 0, {
            key: String(availableTerm), term_name: Number(availableTerm), term_courses: courses
        });

        setFormattedCourseData(newData);

        // If the user is adding a new term with some courses, then update the profile
        if (courses.length > 0) {
            updateProfileCourses(newData);
        }

        if (record) {
            const action = {
                operation: "addTerm",
                payload: {
                    term_name: Number(availableTerm),
                    term_index: i,
                    courses: courses
                }
            };

            addActionToHistory(action);
        }
    }

    const deleteTerm = (i, record = true) => {
        const newData = [...formattedCourseData];

        const action = {
            operation: "deleteTerm",
            payload: {
                term_index: i,
                term_info: newData[i]
            }
        };

        newData.splice(i, 1);
        setFormattedCourseData(newData);

        updateProfileCourses(newData);

        if (record) {
            addActionToHistory(action);
        }
    }

    const deleteCourse = (st, sc, record = true) => {
        const newData = [...formattedCourseData];

        const course_data = newData[st].term_courses[sc];

        newData[st].term_courses.splice(sc, 1);

        setFormattedCourseData(newData);

        const action = {
            operation: "delete",
            source: {
                row_index: st,
                course_index: sc,
                payload: course_data
            }
        };
        
        // save to server
        save_item_to_server('delete_profile_course', {
            key: activeProfile.key,
            course: genericCourse2UserCourse(course_data, course_data["term"])
        });

        if (record) {
            addActionToHistory(action);
        }
        draggingCard.current = null;
    }

    const moveCourse = (st, sc, tt, tc, record = true) => {
        const newData = [...formattedCourseData];
        const sourceCard = newData[st].term_courses[sc];

        // Add dragging course to location in new term
        newData[tt].term_courses.splice(tc, 0, sourceCard);

        // Delete the dragging course's old copy
        newData[st].term_courses.splice(sc + (st === tt && sc > tc), 1);
        sourceCard["term"] = newData[tt]["term_name"];

        sourceCard["code"] = `${sourceCard["code"].split(' ')[0]} ${termToChar(sourceCard["term"])}`

        setFormattedCourseData(newData);

        const action = {
            operation: "move",
            source: {
                row_index: st,
                course_index: sc,
            },
            target: {
                row_index: tt,
                course_index: tc,
            },
        };

        let newCourse = genericCourse2UserCourse(sourceCard, sourceCard["term"]);
        console.log("profile key:", activeProfile.key);
        console.log('newCourse: ', newCourse);
        
        // Save to server
        save_item_to_server('move_profile_course', {
            key: activeProfile.key,
            course: newCourse
        });

        if (record) {
            addActionToHistory(action);
        }
        draggingCard.current = null;
    };

    const swapCourse = (st, sc, tt, tc, record = true) => {
        const newData = [...formattedCourseData];

        const sourceCard = newData[st].term_courses[sc];
        const targetCard = newData[tt].term_courses[tc];

        // Basic legal checks
        const [sourceLegal, sourceMessage] = checkCourseLegalBasic(sourceCard, newData[tt].term_name, false);
        const [targetLegal, targetMessage] = checkCourseLegalBasic(targetCard, newData[st].term_name, false);

        if (!sourceLegal || !targetLegal) {
            message.error(sourceMessage || targetMessage);
            draggingCard.current = null;
            return [false, `Source course move status: ${sourceLegal}, message: ${sourceMessage}\nTarget course move status: ${targetLegal}, message: ${targetMessage}`];
        }

        // Replace dragged card position with the dropped position
        newData[st].term_courses[sc] = targetCard;
        newData[tt].term_courses[tc] = sourceCard;

        // Swap course term
        newData[st].term_courses[sc]["term"] = newData[st]["term_name"];
        newData[tt].term_courses[tc]["term"] = newData[tt]["term_name"];

        setFormattedCourseData(newData);

        const action = {
            operation: "swap",
            source: {
                row_index: st,
                course_index: sc,
            },
            target: {
                row_index: tt,
                course_index: tc,
            },
        };

        let newSourceCourse = genericCourse2UserCourse(newData[st].term_courses[sc], newData[st].term_courses[sc]["term"]);
        console.log('newSourceCourse: ', newSourceCourse);
        
        // Save to server
        save_item_to_server('move_profile_course', {
            key: activeProfile.key,
            course: newSourceCourse
        }, false);

        let newDestCourse = genericCourse2UserCourse(newData[tt].term_courses[tc], newData[tt].term_courses[tc]["term"]);
        console.log('newDestCourse: ', newDestCourse);
        
        // Save to server
        save_item_to_server('move_profile_course', {
            key: activeProfile.key,
            course: newDestCourse
        }, false);

        message.success("Course action saved to server");

        if (record) {
            addActionToHistory(action);
        }

        draggingCard.current = null;
    };

    const addCourse = (course_payload, target_row, target_col, record = true) => {
        const newData = [...formattedCourseData];
        let new_course = dc(course_payload);
        let inferred_new_course = null;

        console.log("Add new course to course table", new_course);

        new_course["status"] = 1;
        new_course["term"] = newData[target_row]["term_name"];
        
        if(new_course['session'] === 'Y'){
            new_course["code"] = `${new_course["code"].split(' ')[0]} Y`

            inferred_new_course = dc(new_course);

            let theOtherTerm = new_course["term"];

            theOtherTerm += theOtherTerm % 10 === 9 ? 2 : -2;

            inferred_new_course["term"] = theOtherTerm;

            console.log("The other term:", theOtherTerm);
        } else {
            new_course["code"] = `${new_course["code"].split(' ')[0]} ${termToChar(new_course["term"])}`
        }

        const [legal, legal_check_message] = checkCourseLegalBasic(new_course, new_course['term']);
        if(!legal){
            draggingCard.current = null;
            return [false, `Target cours could not be added due to: ${legal_check_message}`];
        }

        if(inferred_new_course){
            let inferred_target_row = 0;
            let term_found = false;

            for (; inferred_target_row < formattedCourseData.length; inferred_target_row++) {
                let term = formattedCourseData[inferred_target_row];

                if(term.term_name === inferred_new_course["term"]){
                    term_found = true;
                    break
                }
            }

            if(!term_found){
                let term_location = target_row;
                term_location += inferred_new_course["term"] % 10 === 9 ? -1 : 1;
                console.log("Term not found, creating new term.", inferred_new_course["term"], term_location);

                newData.splice(term_location, 0, {
                    key: String(inferred_new_course["term"]), term_name: Number(inferred_new_course["term"]), term_courses: [inferred_new_course]
                });
                
                const action = {
                    operation: "addTerm",
                    payload: {
                        term_name: Number(inferred_new_course["term"]),
                        term_index: term_location,
                        courses: [inferred_new_course]
                    }
                };
    
                addActionToHistory(action);
            } else {
                console.log("Term found:", inferred_target_row);
                let inferred_course_add_location = newData[inferred_target_row].term_courses.length;
                
                let [legal, legal_check_message] = checkCourseLegalBasic(inferred_new_course, inferred_new_course['term']);

                if(!legal){
                    draggingCard.current = null;
                    return [false, `Inferred course could not be added due to: ${legal_check_message}`];
                }
                
                newData[inferred_target_row].term_courses.splice(inferred_course_add_location, 0, inferred_new_course);
            }
        }
        newData[target_row].term_courses.splice(target_col, 0, new_course);

        setFormattedCourseData(newData);

        const action = {
            operation: "add",
            payload: new_course,
            target: {
                row_index: target_row,
                course_index: target_col,
            },
        };

        const userCourseVariant = genericCourse2UserCourse(new_course, new_course["term"]);
        console.log('Add course:', userCourseVariant);

        // send action to server
        save_item_to_server('add_profile_course', {
            key: activeProfile.key,
            course: userCourseVariant
        });

        if (record) {
            addActionToHistory(action);
        }

        draggingCard.current = null;

        return [true, "Course successfully added"];
    }

    const handleCourseCardDrop = (term, code) => {
        if (draggingCard.current) {
            var term_row_index = 0;
            var term_course_index = 0;

            const newData = [...formattedCourseData];

            // Extract the dragging card data
            let dragCardRow = null;
            let dragCardCourse = null;
            let draggedCard = null;
            console.log(draggingCard.current)
            if (draggingCard.current.new) {
                console.log("handle adding new course")
                draggedCard = draggingCard.current.payload;
            } else {
                dragCardRow = draggingCard.current.term_row_index;
                dragCardCourse = draggingCard.current.term_course_index;
                draggedCard = newData[dragCardRow].term_courses[dragCardCourse];
            }

            // Drop on empty row
            if (code === null) {
                term_row_index = findCourseLocation(formattedCourseData, term, null);

                if (!checkCourseLegalBasic(draggedCard, term)) {
                    draggingCard.current = null;
                    return;
                }

                if (draggingCard.current.new) {
                    addCourse(draggedCard, term_row_index, newData[term_row_index].term_courses.length);
                } else {
                    console.log(newData)
                    console.log(dragCardRow, dragCardCourse, term_row_index);
                    console.log(newData[term_row_index].term_courses.length);

                    moveCourse(dragCardRow, dragCardCourse, term_row_index, newData[term_row_index].term_courses.length);
                }
            } else {
                // Swap
                [term_row_index, term_course_index] = findCourseLocation(formattedCourseData, term, code);

                console.log("SWAPP")

                if (term_row_index === dragCardRow && term_course_index === dragCardCourse) {
                    console.log("NAHHHHH")
                    draggingCard.current = null;
                    return;
                }

                if (!checkCourseLegalBasic(draggedCard, term)) {
                    draggingCard.current = null;
                    return;
                }

                if (newData[term_row_index].term_courses[term_course_index]["code"].includes("PEY")) {
                    draggingCard.current = null;
                    return;
                }

                if (draggingCard.current.new) {
                    message.error("A new course can not be swapped with an existing course!")
                } else {
                    swapCourse(dragCardRow, dragCardCourse, term_row_index, term_course_index);
                }
            }
        }
    };

    const findCoursePosition = (rootRef, source_row_index, source_course_index) => {
        const tableDom = rootRef.current.querySelector(".ant-table-content");
        const termRowDom = tableDom.querySelectorAll("tbody tr")[source_row_index].querySelectorAll("td")[1].querySelector("div");
        var courseContainer = termRowDom.querySelectorAll("div .ant-col");

        // If source course index is -1, then it's the last one
        source_course_index = source_course_index === -1 ? courseContainer.length - 1 : source_course_index;
        courseContainer = courseContainer[source_course_index];
        const courseContainerPosition = courseContainer.getBoundingClientRect();

        return [courseContainer, courseContainerPosition];
    };

    const singleCourseCardAnimation = async (root_ref, source_row_index, source_course_index, target_row_index, target_course_index) => {
        console.log("Moving course card:", source_row_index, source_course_index, target_row_index, target_course_index);
    
        const [courseContainer, courseContainerPosition] = findCoursePosition(root_ref, source_row_index, source_course_index);
        const [targetContainer, targetContainerPosition] = findCoursePosition(root_ref, target_row_index, target_course_index);
    
        const translateX =
            targetContainerPosition.left - courseContainerPosition.left + targetContainerPosition.width * (target_course_index === -1);
        const translateY = targetContainerPosition.top - courseContainerPosition.top;
    
        const transformData = `translate(${translateX}px, ${translateY}px)`;
        const transitionData = `transform 0.5s ease-in-out`;
    
        console.log("transformData:", transformData);
    
        courseContainer.style.transform = transformData;
        courseContainer.style.transition = transitionData;
    
        // Return a promise that resolves after the animation is complete
        return new Promise((resolve) => {
            sleep(500);
            setTimeout(() => {
                courseContainer.style.transition = "";
                courseContainer.style.transform = "";
                resolve();
            }, 500);
        });
    };

    // When a course is moved, the courses before and after it should move accordingly, use singleCourseCardAnimation
    // For example, if a course at (20229, 3) is moved to (20231, 1), then the courses at (20229, 4) ... (20229, last_course) should move one to the left and the course at (20231, 1) should move to (20231, 2)
    const moveCourseCardAnimation = async (root_ref, source_row_index, source_course_index, target_row_index, target_course_index) => {
        // debugger
        let animation_queue = [];

        // Animate the other course cards on the same row
        for(const course of formattedCourseData[source_row_index].term_courses.slice(source_course_index + 1)){
            const [row_index, course_index] = findCourseLocation(formattedCourseData, course.term, course.code);
            
            animation_queue.push(singleCourseCardAnimation(root_ref, row_index, course_index, row_index, course_index - 1));
        }

        // Animate the moving course card
        if(source_row_index === target_row_index){
            animation_queue.push(singleCourseCardAnimation(root_ref, source_row_index, source_course_index, target_row_index, formattedCourseData[source_row_index].term_courses.length - 1));
        } else {
            animation_queue.push(singleCourseCardAnimation(root_ref, source_row_index, source_course_index, target_row_index, target_course_index));
        }

        // return if all animations are done
        await Promise.all(animation_queue);
        console.log("All animations are done");
    };

    const checkCourseLegalBasic = (course, target_term, show_message=true) => {
        // Basic legal checks without needing to query server
        const targetTermStr = termToStr(target_term);
        console.log("checkCourseLegalBasic:", course);

        var error_message = null;

        // Some obivious ones
        if (course["code"].includes("PEY")) {
            error_message = `Illegal Course Action: PEY course is not movable`;
        } else if (course["status"] === 0) {
            error_message = "Illegal Course Action: Passed course is not movable";
        } else if (course["status"] === 2) {
            error_message = "Illegal Course Action: Failed course is not movable";
        }
    
        if (error_message !== null) {
            if(show_message){
                message.error(error_message);
            }

            return [false, error_message];
    
        }

        if(!course['twin'] && !course[targetTermStr]){
            if(show_message){
                message.error(`Illegal Course Action: Target course is not offered in the ${targetTermStr} term`)
            } 
            
            return [false, `Illegal Course Action: Target course is not offered in the ${targetTermStr} term`];
        }

        // Check if the target term already has the course
        for(const term of formattedCourseData){
            if(term.term_name === target_term){
                let total_credit = 0;
                for(const tc of term.term_courses){                
                    if(tc.code.split(' ')[0] === course.code.split(' ')[0]){

                        if(show_message){
                            message.error("Illegal Course Action: Target Course Already Exists");
                        }

                        return [false, "Illegal Course Action: Target Course Already Exists"];
                    }
                }
                
                // TODO: Add credit to user course modal
                if(term.term_courses.length >= 6){
                // if(total_credit > 3.0){

                    if(show_message){
                        message.error("Illegal Course Action: Max Course Load Reached at the Target Term");
                    }

                    return [false, "Illegal Course Action: Max Course Load Reached at the Target Term"];
                }
            }
        }
        
        
        return [true, "The course is legal to be added to the target term"];
    }

    /**
     * Manipulates the course list based on the specified action.
     * 
     * @param {Object} root_ref - The root reference for animations.
     * @param {Object} action - The action to be performed.
     * @param {string} action.action - The type of action to perform. Possible values are "add", "swap", "move", "delete".
     * @param {Object} action.source_course - The source course details.
     * @param {string} action.source_course.code - The code of the source course.
     * @param {string} action.source_course.term - The term of the source course.
     * @param {Object} [action.dest_course] - The destination course details (required for "add", "swap", and "move" actions).
     * @param {string} [action.dest_course.code] - The code of the destination course (required for "swap").
     * @param {string} [action.dest_course.term] - The term of the destination course (required for "swap" and "move").
     * @param {number} [action.dest_course.row_index] - The row index of the destination course (required for "add").
     * @param {number} [action.dest_course.course_index] - The course index of the destination course (required for "add").
     * 
     * @returns {Promise<Array>} A promise that resolves to an array where the first element is a boolean indicating success, and the second element is a message.
     * 
     * @example
     * // Add a new course
     * manipulateCourseList(root_ref, {
     *   action: "add",
     *   source_course: { code: "CSC108", term: 20229 },
     *   dest_course: { row_index: 1, course_index: 2 }
     * });
     * 
     * @example
     * // Swap two courses
     * manipulateCourseList(root_ref, {
     *   action: "swap",
     *   source_course: { code: "CSC108", term: 20229 },
     *   dest_course: { code: "MAT137", term: 20231 }
     * });
     * 
     * @example
     * // Move a course to another term
     * manipulateCourseList(root_ref, {
     *   action: "move",
     *   source_course: { code: "CSC108", term: 20229 },
     *   dest_course: { term: 20231 }
     * });
     * 
     * @example
     * // Delete a course
     * manipulateCourseList(root_ref, {
     *   action: "delete",
     *   source_course: { code: "CSC108", term: 20229 }
     * });
     */
    const manipulateCourseList = async (root_ref, action) => {
        // There are a few possible operations:
        //      1. Add a brand new course to a term
        //      2. Swap two courses at two different terms
        //      3. Move one course from a term to another term
        //      4. Delete a course

        const action_name = action["action"].toLowerCase();

        if (action_name === "add") {
            addCourse(action["source_course"], action["dest_course"]["row_index"], action["dest_course"]["course_index"]);

            let action_result = "The course is successfully added to the term";

            return [true, action_result];
        } else if (action_name === "swap") {
            const target_code = action["dest_course"]["code"];
            const target_term = action["dest_course"]["term"];
            const [target_row_index, target_course_index] = findCourseLocation(formattedCourseData, target_term, target_code);

            const source_code = action["source_course"]["code"];
            const source_term = action["source_course"]["term"];
            const [source_row_index, source_course_index] = findCourseLocation(formattedCourseData, source_term, source_code);

            if (target_course_index === -1) {
                return [false, `The course ${action["dest_course"]["code"]} was not found in the term ${action["dest_course"]["term"]}`];
            }

            if (target_row_index === -1) {
                return [false, `The term term ${action["dest_course"]["term"]} does not exist.`];
            }

            if (source_course_index === -1) {
                return [
                    false,
                    `The course ${action["source_course"]["code"]} was not found in the term ${action["source_course"]["term"]}`,
                ];
            }

            if (source_row_index === -1) {
                return [false, `The term term ${action["source_course"]["term"]} does not exist.`];
            }

            const newData = [...formattedCourseData];
            const target_card = newData[target_row_index].term_courses[target_course_index];

            moveCourseCardAnimation(root_ref, source_row_index, source_course_index, target_row_index, target_course_index);
            moveCourseCardAnimation(root_ref, target_row_index, target_course_index, source_row_index, source_course_index);

            newData[target_row_index].term_courses[target_course_index] = newData[source_row_index].term_courses[source_course_index];
            newData[source_row_index].term_courses[source_course_index] = target_card;

            newData[target_row_index].term_courses[target_course_index]["term"] = newData[target_row_index]["term_name"];
            newData[source_row_index].term_courses[source_course_index]["term"] = newData[source_row_index]["term_name"];

            await sleep(500);

            setFormattedCourseData(newData);

            const action_result = `The course ${action["source_course"]["code"]} is successfully swapped with the course ${action["dest_course"]["code"]}`;
            console.log(action_result);
            return [true, action_result];
        } else if (action_name === "move") {
            const target_term = action["dest_course"]["term"];
            const target_row_index = findCourseLocation(formattedCourseData, target_term, null);

            const source_code = action["source_course"]["code"];
            const source_term = action["source_course"]["term"];
            const [source_row_index, source_course_index] = findCourseLocation(formattedCourseData, source_term, source_code);

            if (target_row_index === -1) {
                return [false, `The term term ${action["dest_course"]["term"]} does not exist.`];
            }

            if (source_course_index === -1) {
                return [
                    false,
                    `The course ${action["source_course"]["code"]} was not found in the term ${action["source_course"]["term"]}`,
                ];
            }

            if (source_row_index === -1) {
                return [false, `The term term ${action["source_course"]["term"]} does not exist.`];
            }

            const newData = dc(formattedCourseData);

            console.log(source_row_index, source_course_index);

            // Animation
            await moveCourseCardAnimation(root_ref, source_row_index, source_course_index, target_row_index, -1);

            newData[target_row_index].term_courses.push(newData[source_row_index].term_courses[source_course_index]);
            newData[target_row_index].term_courses.at(-1)["term"] = newData[target_row_index]["term_name"];

            newData[source_row_index].term_courses.splice(source_course_index, 1);

            console.log("newData:", newData);
            setFormattedCourseData(newData);

            const action_result = `The course ${action["source_course"]["code"]} is successfully moved to the term ${target_term}`;
            console.log(action_result);
            return [true, action_result];
        } else if (action_name === "delete") {
            const source_code = action["source_course"]["code"];
            const source_term = action["source_course"]["term"];
            const [source_row_index, source_course_index] = findCourseLocation(formattedCourseData, source_term, source_code);

            if (source_course_index === -1) {
                return [
                    false,
                    `The course ${action["source_course"]["code"]} was not found in the term ${action["source_course"]["term"]}`,
                ];
            }

            if (source_row_index === -1) {
                return [false, `The term term ${action["source_course"]["term"]} does not exist.`];
            }

            const newData = [...formattedCourseData];

            newData[source_row_index].term_courses.splice(source_course_index, 1);

            setFormattedCourseData(newData);

            const action_result = `The course ${action["source_course"]["code"]} is successfully deleted`;
            console.log(action_result);
            return [true, action_result];
        } else {
            console.log("The requested action is not unrecognized.");

            return [false, "The requested action is not unrecognized. The available actions are: [move, swap, delete, add]."];
        }
    };

    return { checkCourseLegalBasic, deleteTerm, addTerm, deleteCourse, addCourse, moveCourse, swapCourse, handleCourseCardDrop, manipulateCourseList };
};

export default useCourseActions;
