import { Input, Button, Space, Collapse, List, Typography, Flex, Popconfirm, message} from "antd";
import { useState } from "react";
import CryptoJS from "crypto-js";
import { InputButtonAction, AdminAddProfileCourse, AdminAddProfile, CommandRunner } from "./PanelComponents";
import LaForgeButton from "../App/LaforgeButton";

const AdminPanelContent = () => {
    // List of features:
    //  * Add a user
    //  * Get database status
    //  * Modify a course
    //  * Delete all courses
    //  * Delete a specific course
    //  * Modify a user information
    //  * Get a user
    //  * Delete all users
    //  * Delete a specific user

    const text = "hey";
    const [db_stats, setDB_stats] = useState([
        "Racing car sprays burning fuel into crowd.",
        "Japanese princess to wed commoner.",
        "Australian walks 100km after outback crash.",
        "Man charged over missing wedding girl.",
        "Los Angeles battles huge wildfires.",
    ]);

    

    function getDatabaseStats() {
        setDB_stats(["Database statistics is now refreshed."]);
    }

    function deleteAllCourses() {
        message.success("All courses deleted from the Database.");
    }

    function deleteAllUsers() {
        message.success("All users deleted from the Database.");
    }

    const adminItems = [
        {
            key: "run_command",
            label: "Run a Command",
            children: (
                <CommandRunner/>
            ),
        },
        {
            key: "add_profile",
            label: "Add profile to user",
            children: (
                <AdminAddProfile/>
            )
        },
        {
            key: "add_profile_course",
            label: "Add courses to a user's profile",
            children: (
                <AdminAddProfileCourse/>
            )
        },
        {
            key: "get_db_stats",
            label: "Get Database Statistics",
            children: (
                <div>
                    <List
                        header={
                            <Flex align="center" justify="space-between">
                                <Typography.Title level={2}>Database Statistics</Typography.Title>{" "}
                                <Button onClick={getDatabaseStats}>Refresh</Button>{" "}
                            </Flex>
                        }
                        bordered
                        dataSource={db_stats}
                        renderItem={(item) => <List.Item>{item}</List.Item>}
                    />
                </div>
            ),
        },
        {
            key: "query_course",
            label: "Query a Course",
            children: (
                <InputButtonAction action={()=>{console.log("CLICK")}}/>
            ),
        },
        {
            key: "modify_course",
            label: "Modify a Course",
            children: <p>{text}</p>,
        },
        {
            key: "delete_all_course",
            label: "Delete All Courses",
            children: (
                <Popconfirm
                    title="Delete All Courses"
                    description="Are you sure to perform this action?"
                    okText="Yes"
                    cancelText="No"
                    placement="right"
                    onConfirm={(e) => {
                        console.log(e);
                        deleteAllCourses();
                    }}
                >
                    <Button danger type="primary">
                        Delete All Courses
                    </Button>
                </Popconfirm>
            ),
        },
        {
            key: "delete_course",
            label: "Delete a course",
            children: <p>{text}</p>,
        },
        {
            key: "query_user",
            label: "Query a User",
            children: <p>{text}</p>,
        },
        {
            key: "modify_user",
            label: "Modify a User",
            children: <p>{text}</p>,
        },
        {
            key: "delete_all_user",
            label: "Delete All Users",
            children: (
                <Popconfirm
                    title="Delete All Users"
                    description="Are you sure to perform this action?"
                    okText="Yes"
                    cancelText="No"
                    placement="right"
                    onConfirm={(e) => {
                        console.log(e);
                        deleteAllUsers();
                    }}
                >
                    <Button danger type="primary">
                        Delete All Users
                    </Button>
                </Popconfirm>
            ),
        },
        {
            key: "delete_user",
            label: "Delete a User",
            children: <p>{text}</p>,
        },
    ];

    return (
        <div style={{ padding: "12px" }}>
            <h1>Admin Panel</h1>

            <Collapse
                items={adminItems}
                defaultActiveKey={["add_user"]}
                onChange={(a, b, c) => {
                    console.log(a, b, c);
                }}
            />
        </div>
    );
};

const AdminPanel = () => {
    const [adminLogin, setAdminLogin] = useState(true);
    const [loginInputValue, setLoginInputValue] = useState("");
    const hardcodedHash = "6ca13d52ca70c883e0f0bb101e425a89e8624de51db2d2392593af6a84118090";

    function admin_login(loginInputValue) {
        const hashedPassword = CryptoJS.SHA256(loginInputValue).toString();

        console.log(hashedPassword);
        if (hashedPassword === hardcodedHash) {
            setAdminLogin(true);
        } else {
            alert("Login credential is not correct!");
        }
    }

    return (
        <>
            {adminLogin ? (
                <AdminPanelContent></AdminPanelContent>
            ) : (
                <div>
                    <h1>Admin Panel Log in</h1>
                    <LaForgeButton nextPath="/admin"/>
                    {/* <Space.Compact style={{ width: "100%" }}>
                        <Input.Password
                            value={loginInputValue}
                            onChange={(event) => {
                                setLoginInputValue(event.target.value);
                            }}
                            onPressEnter={() => {
                                admin_login(loginInputValue);
                            }}
                        />
                        <Button
                            type="primary"
                            onClick={() => {
                                admin_login(loginInputValue);
                            }}
                            style={{ height: 40 }}
                        >
                            Login
                        </Button>
                    </Space.Compact> */}
                </div>
            )}
        </>
    );
};

export default AdminPanel;
