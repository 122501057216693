import { message } from "antd";
import axios from "axios";
import { json, useNavigate } from "react-router-dom";
import { useAppContext } from "./App/AppContext";
import { useMetrics } from "./SiteMetricContext";
import pako from 'pako';
import brotliPromise from 'brotli-wasm';

const local_server_api_addr = 'http://localhost:8000/api/';
const aws_server_api_addr = 'https://smartgellan.compeng.gg/api/';
const server_api_addr = aws_server_api_addr;

const defaultFilterData = {
    artsci: {
        approvedOnly: false,
        type: ["cs", "hss", "other"],
    },
    artsci_enabled: true,
    avoid_conflict: false,
    ceab: [],
    eng: {
        area: ["1", "2", "3", "4", "5", "6", "7", "O"],
        type: ["kernel", "depth", "other", 'free', 'required'],
    },
    eng_enabled: true,
    numReturn: 5,
    semester: []
};


const format_course_data_source = (groupedCourses) => {
    var formattedDataSource = [];

    var maxCourseCount = 1;
    for (var cur_courses of Object.values(groupedCourses)) {
        if (cur_courses.length > maxCourseCount) {
            maxCourseCount = cur_courses.length;
        }
    }

    for (const [term, courses] of Object.entries(groupedCourses)) {
        const curTermCourseList = [];

        // ceab: [0, 0, 1920, 0, 0] (5)
        // id: 1
        // twin: 0

        for (var i = 0; i < maxCourseCount; i++) {
            if (i < courses.length) {
                curTermCourseList.push({
                    name: courses[i]["name"],
                    code: courses[i]["code"],
                    status: courses[i]["status"],
                    term: courses[i]["term"],

                    area: courses[i]["area"],
                    type: courses[i]["type"],

                    ceab: courses[i]["ceab"],
                    twin: courses[i]["twin"],
                });
            }
        }

        formattedDataSource.push({
            key: `${term}`,
            term_name: Number(term),
            term_courses: curTermCourseList,
        });
    }

    return formattedDataSource;
};

const alphanumerical = () => {
    const chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let sequence = "";
    for (let i = 0; i < 9; i++) {
        const randomIndex = Math.floor(Math.random() * chars.length);
        sequence += chars[randomIndex];
    }
    return sequence;
};

function findCourseLocation(formattedCourseData, term, code) {
    term = Number(term);

    var term_row_index = 0;
    var valid_term_row = false;
    for (; term_row_index < formattedCourseData.length; term_row_index++) {
        if (formattedCourseData[term_row_index]["term_name"] === term) {
            valid_term_row = true;
            break;
        }
    }

    if (!valid_term_row) {
        term_row_index = -1;
        return [-1, -1];
    }

    if (code === null) {
        return term_row_index;
    }

    var term_course_index = 0;
    var valid_course_index = false;

    for (; term_course_index < formattedCourseData[term_row_index]["term_courses"].length; term_course_index++) {
        if (formattedCourseData[term_row_index]["term_courses"][term_course_index]["code"] === code) {
            valid_course_index = true;
            break;
        }
    }

    if (!valid_course_index) {
        term_course_index = -1;
    }

    return [term_row_index, term_course_index];
}

const requestCourseBasicInfo = async (code) => {
    return axios.post(server_api_addr, {
        request: "get_course_basic",
        payload: {
            code: code,
        },
    })
        .then((response) => {
            console.log(response.data);

            // setCurrentCourseDetails(response.data);
            const data = response.data;
            const formatted_data = {
                'CS': 0,
                'ED': 0,
                'ES': 0,
                'Math': 0,
                'NS': 0,
                'area': 6,
                'code': code,
                'name': "Default name for the new course.",
                'status': 1,
                'term': null,
                'type': "C",
            }

            return formatted_data;
        })
        .catch((error) => {
            if (error.response) {
                console.log(error.response.data);
                alert(JSON.stringify(error.response.data));
            } else {
                console.error("Error", error);
            }
            console.log("BRUHHHHH")
            const formatted_data = {
                'CS': 0,
                'ED': 0,
                'ES': 0,
                'Math': 0,
                'NS': 0,
                'area': 6,
                'code': code,
                'name': "Default name for the new course.",
                'status': 1,
                'term': null,
                'type': "C",
            }

            return formatted_data;
        });
}

const processCourseDetails = (data) => {
    console.log("Raw course data:", data);
    return {
        name: data.name,
        code: data.code.split(' ')[0], // Does not include F/S
        session: data.code.split(' ')[1],
        description: data.description, // This could be ECE244H1 and/or ECE243H1, need a way to distinguish
        prerequisites: ["N/A"],
        corequisites: ["N/A"],
        exclusions: ["N/A"],

        creditWeight: data.credit / 100,

        // 1, 2, 3, 4, 5, 6, 7(Science and Math), O(Not ECE)
        // Server responde with '123' (which means 1 and 2 and 3)
        area: data.area,

        // Kernel, Depth, HSS, CS, Free, None
        // ('K', 'Kernel'), ('D', 'Depth'), ('H', 'HSS'), ('C', 'CS'), ('F', 'Free'), ('O', 'Other'),
        type: data.type,

        // not Deprecated
        fall: data.code.split(' ').at(-1).trim().toLowerCase() === 'f' || data.twin ? true : false,
        winter: data.code.split(' ').at(-1).trim().toLowerCase() === 's' || data.twin ? true : false,
        summer: false,
        twin: data.twin,

        // "20249;20259;"
        offered: data.offered.split(";"),
        delivery: data.delivery,
        au_dist: data.au || [0, 0, 0, 0, 0],
        ceab: data.ceab || [0, 0, 0, 0, 0],

        // Default to in process/planned
        // status: 0,
    }
}

const requestCourseDetails = async (code) => {
    console.log('Request course details:', code)
    return axios.post(server_api_addr, {
        request: "get_course",
        payload: {
            code: code,
        },
    })
        .then((response) => {
            console.log("Got course data from server:")
            console.log(response.data);

            // setCurrentCourseDetails(response.data);
            const data = response.data;

            // return formatted_data;
            return processCourseDetails(data);
        })
        .catch((error) => {
            if (error.response) {
                console.log(error.response.data);
                alert(JSON.stringify(error.response.data));
            } else {
                console.error("Error", error);
            }
        });
};

function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
}

function getCourseColor(status) {
    if (status === 0) {
        return "black";
    } else if (status === 1) {
        return "#f90";
    } else {
        return "red";
    }
}

function calculateDisplayCourseCardWidth(screenWidth) {
    const cardWidth = Math.ceil(screenWidth / 180) * 10;
    console.log(cardWidth);
    return 140;
}

const HTMLRenderer = ({ htmlCode }) => {
    return (
        <div dangerouslySetInnerHTML={{ __html: htmlCode }} />
    );
};

const useRequestWithNavigateJWT = () => {
    const navigate = useNavigate();

    const api = axios.create({
        baseURL: server_api_addr,
    });

    // Function to refresh the access token
    const refreshAccessToken = async () => {
        try {
            const tokens = JSON.parse(localStorage.getItem('jwt'));
            const refreshToken = tokens?.refresh;

            const response = await axios.post(`${server_api_addr}/refresh`, {
                refresh: refreshToken,
            });

            const newAccessToken = response.data.access;
            // Update the access token in localStorage
            localStorage.setItem('jwt', JSON.stringify({
                ...tokens,
                access: newAccessToken
            }));

            return newAccessToken;
        } catch (error) {
            console.error("Refresh token is invalid or expired");
            navigate('/login'); // Redirect to login if refresh fails
            return null;
        }
    };

    // Add request interceptor to include the access token in headers
    api.interceptors.request.use(
        async (config) => {
            const tokens = JSON.parse(localStorage.getItem('jwt'));
            const accessToken = tokens?.access;

            if (accessToken) {
                config.headers.Authorization = `Bearer ${accessToken}`;
            }
            return config;
        },
        (error) => Promise.reject(error)
    );

    // Add response interceptor to handle 401 errors and retry failed requests
    api.interceptors.response.use(
        (response) => response,
        async (error) => {
            const originalRequest = error.config;

            // If access token expired, try to refresh it
            if (error.response?.status === 401 && !originalRequest._retry) {
                originalRequest._retry = true;

                const newAccessToken = await refreshAccessToken();

                if (newAccessToken) {
                    originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
                    return api(originalRequest); // Retry the original request
                }
            }

            return Promise.reject(error);
        }
    );

    // Wrapper function to send requests
    const request = async (endpoint, payload) => {
        try {
            const response = await api.post(
                endpoint,
                {
                    request: endpoint,
                    payload: payload
                }
            );
            return response.data;
        } catch (error) {
            if (error.response) {
                console.error(error.response.data);
                navigate('/error', {
                    state: {
                        htmlCode: error.response?.data || "<h1>Server Error</h1><p>An error occurred.</p>"
                    }
                });
            } else {
                console.error("Error", error);
                navigate('/error', {
                    state: {
                        htmlCode: "<h1>Server Error</h1><p>An error occurred.</p>"
                    }
                });
            }
            return null;
        }
    };

    return request;
};


function setItemWithExpiry(key, value, ttl) {
    const now = new Date();

    const item = {
        value: value,
        expiry: now.getTime() + ttl, // ttl is in milliseconds
    };

    localStorage.setItem(key, JSON.stringify(item));
}

// Retrieve data and check for expiration
function getItemWithExpiry(key) {
    const itemStr = localStorage.getItem(key);

    // If item doesn't exist, return null
    if (!itemStr) {
        return null;
    }

    const item = JSON.parse(itemStr);
    const now = new Date();

    // If expired, remove the item and return null
    if (now.getTime() > item.expiry) {
        localStorage.removeItem(key);
        return null;
    }

    return item.value;
}

function termToStr(term) {
    const term_num = term % 10;

    if (term_num === 5) {
        return 'summer'
    } else if (term_num === 1) {
        return 'winter'
    } else if (term_num === 9) {
        return 'fall'
    }

    return 'Unkown'
}

function termToChar(term) {
    const term_num = term % 10;

    if (term_num === 5) {
        return 'F'
    } else if (term_num === 1) {
        return 'S'
    } else if (term_num === 9) {
        return 'F'
    }

    return 'F'
}

async function requestAuth(dataOrToken, jwt, setAndStoreJwt) {
    const url = 'https://smartgellan.compeng.gg/api/auth_user/'

    let headers = {
        'Content-Type': 'application/json',
    };

    // Check if dataOrToken is a token and set it in the headers
    if (typeof dataOrToken === 'string') {
        headers['Authorization'] = `Bearer ${dataOrToken}`;
    }

    try {
        const response = await axios.post(url, typeof dataOrToken === 'object' ? dataOrToken : {}, {
            headers,
            withCredentials: true,
        });

        // Return the response directly if no authorization error occurs
        return response;
    } catch (error) {
        // If unauthorized, attempt to refresh the token
        if (error.response && error.response.status === 401) {
            // Attempt token refresh if jwt.access is undefined
            if (!jwt || !jwt.access) {
                setAndStoreJwt(undefined);
                throw error;
            }

            try {
                const refreshToken = jwt.refresh;
                const refreshUrl = 'https://smartgellan.compeng.gg/api/auth_user/refresh/';
                const refreshResponse = await axios.post(
                    refreshUrl,
                    { refresh: refreshToken },
                    { headers: { 'Content-Type': 'application/json' } }
                );

                const newAccessToken = refreshResponse.data.access;
                if (!newAccessToken) {
                    setAndStoreJwt(undefined);
                    throw error;
                }

                // Store the new access token and retry the request
                setAndStoreJwt({ access: newAccessToken, refresh: refreshToken });
                headers['Authorization'] = `Bearer ${newAccessToken}`;

                // Retry original request with new token
                return await axios.post(url, typeof dataOrToken === 'object' ? dataOrToken : {}, {
                    headers,
                    withCredentials: true,
                });
            } catch (refreshError) {
                setAndStoreJwt(undefined);
                throw refreshError;
            }
        } else {
            // Throw any other errors that occur during the request
            throw error;
        }
    }
}

async function compressData(dataToSend, headers, compresser_config={ 
    quality: 5,
    lgwin: 22,
}) {
    const jsonString = JSON.stringify(dataToSend);
    const size = jsonString.length;

    let finalData;
    let chosenAlgo;

    // Tweak this threshold to change the compression algorithm choice
    let compression_threshold = 5_000;

    // Choose compression algorithm based on size heuristics
    if (size < compression_threshold) {
        // Small payload: no compression
        chosenAlgo = 'none';
    } else {
        // Larger payload: use brotli for better compression ratio
        chosenAlgo = 'brotli';
    }

    chosenAlgo = 'none';

    if (chosenAlgo === 'brotli') {
        const brotli = await brotliPromise; // Import is async in browsers due to wasm requirements!

        const textEncoder = new TextEncoder();
        
        const uncompressedData = textEncoder.encode(jsonString);
        finalData = brotli.compress(uncompressedData, compresser_config);        

        headers['Content-Encoding'] = 'br';
        headers['Content-Type'] = 'application/octet-stream';
    } else {
        // No compression
        headers['Content-Type'] = 'application/json';
        finalData = jsonString;
    }

    return finalData;
}

async function decompressData(data, contentEncoding='br') {
    // use the algorithm from decompressResponse
    let decompressedData = null;
    let responseData = null;

    if (contentEncoding === 'gzip') {
        // Decompress gzip response
        decompressedData = pako.ungzip(new Uint8Array(data), { to: 'string' });
    } else if (contentEncoding === 'br') {
        const brotli = await brotliPromise; // Import is async in browsers due to wasm requirements!

        const textDecoder = new TextDecoder();
        
        decompressedData = textDecoder.decode(brotli.decompress(data));
    } else {
        // No compression or compression handled by the browser
        const decoder = new TextDecoder();
        decompressedData = decoder.decode(new Uint8Array(data));
    }

    try{
        responseData = JSON.parse(decompressedData);
    } catch (e) {
        responseData = decompressedData;
    }    

    return responseData;
}

async function decompressResponse(response) {
    let contentEncoding = response.headers['content-encoding'] || null;

    return decompressData(response.data, contentEncoding);
}

/**
 * Custom hook that provides a request function with navigation and metrics tracking.
 *
 * @returns {Function} request - The request function to make API calls.
 *
 * @example
 * const request = useRequestWithNavigate();
 * const response = await request('/api/endpoint', { key: 'value' });
 *
 * @typedef {Object} Metrics
 * @property {number} total_traffic - Total number of requests made.
 * @property {number} total_payload_size - Total size of all payloads sent.
 * @property {number} total_uncompressed_payload_size - Total size of all uncompressed payloads sent.
 * @property {number} avg_payload_size - Average size of payloads sent.
 * @property {number} avg_uncompressed_payload_size - Average size of uncompressed payloads sent.
 * @property {number} avg_payload_compression_ratio - Average compression ratio of payloads.
 * @property {number} total_response_size - Total size of all responses received.
 * @property {number} total_uncompressed_response_size - Total size of all uncompressed responses received.
 * @property {number} avg_response_size - Average size of responses received.
 * @property {number} avg_uncompressed_response_size - Average size of uncompressed responses received.
 * @property {number} avg_response_compression_ratio - Average compression ratio of responses.
 * @property {number} avg_communication_time - Average time taken for communication.
 * @property {number} total_compression_time - Total time taken for compression.
 * @property {number} avg_compression_time - Average time taken for compression.
 * @property {number} total_decompression_time - Total time taken for decompression.
 * @property {number} avg_decompression_time - Average time taken for decompression.
 * @property {number} total_request_time - Total time taken for requests.
 * @property {number} avg_request_time - Average time taken for requests.
 * @property {Array<Object>} request_history - History of all requests made.
 */
const useRequestWithNavigate = () => {
    const navigate = useNavigate();
    const { updateMetrics } = useMetrics(); // Access shared metrics via useRef

    const request = async (endpoint, payload) => {
        // Initialize variables for metrics
        let requestBeginTime = performance.now();
        let compressionStartTime, compressionEndTime, compressionTime = 0;
        let decompressionStartTime, decompressionEndTime, decompressionTime = 0;
        let dataToSend, finalData;
        let uncompressedPayloadSize = 0, trafficSize = 0, payloadCompressionRatio = 1;
        let uncompressedResponseSize = 0, responseSize = 0, responseCompressionRatio = 1;
        let responseData;
        let requestEndTime, requestTime;

        const err = new Error();
        const call_stack = err.stack;

        try {
            const tokens = JSON.parse(localStorage.getItem('jwt'));
            const accessToken = tokens?.access;
            let headers = accessToken ? { Authorization: `Bearer ${accessToken}` } : {};

            console.log('Send request to:', endpoint);
            console.log('Payload:', payload);

            // Measure compression time
            compressionStartTime = performance.now();
            dataToSend = { request: endpoint, payload: payload };
            uncompressedPayloadSize = JSON.stringify(dataToSend).length;

            let finalData = await compressData(dataToSend, headers);

            compressionEndTime = performance.now();
            compressionTime = compressionEndTime - compressionStartTime;

            // Calculate traffic size (compressed payload size)
            trafficSize = typeof finalData === 'string' ? finalData.length : finalData.byteLength;

            // Calculate payload compression ratio
            payloadCompressionRatio = uncompressedPayloadSize / trafficSize;

            // Record communication start time
            const startTime = performance.now();
            const response = await axios.post(server_api_addr, finalData, {
                headers,
                responseType: 'arraybuffer', // To handle compressed responses
            });
            const endTime = performance.now();

            // Get response data size (compressed response size)
            responseSize = typeof response.data === 'string' ? response.data.length : response.data.byteLength;

            // Handle the response data
            decompressionStartTime = performance.now();

            responseData = await decompressResponse(response);

            decompressionEndTime = performance.now();
            decompressionTime = decompressionEndTime - decompressionStartTime;

            // Calculate uncompressed response size
            uncompressedResponseSize = JSON.stringify(responseData).length;

            // Calculate response compression ratio
            responseCompressionRatio = uncompressedResponseSize / responseSize;

            // Total request time
            requestEndTime = performance.now();
            requestTime = requestEndTime - requestBeginTime;

            // Collect current request metrics
            let cur_request_metrics = {
                endpoint: endpoint,
                payload: payload,
                response: responseData,

                payload_size: trafficSize,
                uncompressed_payload_size: uncompressedPayloadSize,
                payload_compression_ratio: payloadCompressionRatio,

                response_size: responseSize,
                uncompressed_response_size: uncompressedResponseSize,
                response_compression_ratio: responseCompressionRatio,

                compression_time: compressionTime,
                decompression_time: decompressionTime,
                request_time: requestTime,
                success: true,

                call_stack: call_stack,
            };

            // Update metrics using updateMetrics function
            updateMetrics((metrics) => {
                const newTotalTraffic = metrics.total_traffic + 1;
                const newTotalPayloadSize = (metrics.total_payload_size || 0) + trafficSize;
                const newTotalUncompressedPayloadSize = (metrics.total_uncompressed_payload_size || 0) + uncompressedPayloadSize;
                const newTotalCompressionTime = (metrics.total_compression_time || 0) + compressionTime;
                const newTotalDecompressionTime = (metrics.total_decompression_time || 0) + decompressionTime;
                const newTotalRequestTime = (metrics.total_request_time || 0) + requestTime;
                const newTotalResponseSize = (metrics.total_response_size || 0) + responseSize;
                const newTotalUncompressedResponseSize = (metrics.total_uncompressed_response_size || 0) + uncompressedResponseSize;

                metrics.total_traffic = newTotalTraffic;

                metrics.total_payload_size = newTotalPayloadSize;
                metrics.total_uncompressed_payload_size = newTotalUncompressedPayloadSize;
                metrics.avg_payload_size = newTotalPayloadSize / newTotalTraffic;
                metrics.avg_uncompressed_payload_size = newTotalUncompressedPayloadSize / newTotalTraffic;
                metrics.avg_payload_compression_ratio = newTotalUncompressedPayloadSize / newTotalPayloadSize;

                metrics.total_response_size = newTotalResponseSize;
                metrics.total_uncompressed_response_size = newTotalUncompressedResponseSize;
                metrics.avg_response_size = newTotalResponseSize / newTotalTraffic;
                metrics.avg_uncompressed_response_size = newTotalUncompressedResponseSize / newTotalTraffic;
                metrics.avg_response_compression_ratio = newTotalUncompressedResponseSize / newTotalResponseSize;

                metrics.avg_communication_time =
                    ((metrics.avg_communication_time || 0) * (metrics.total_traffic - 1) + (endTime - startTime)) / newTotalTraffic;

                metrics.total_compression_time = newTotalCompressionTime;
                metrics.avg_compression_time = newTotalCompressionTime / newTotalTraffic;

                metrics.total_decompression_time = newTotalDecompressionTime;
                metrics.avg_decompression_time = newTotalDecompressionTime / newTotalTraffic;

                metrics.total_request_time = newTotalRequestTime;
                metrics.avg_request_time = newTotalRequestTime / newTotalTraffic;

                if (!metrics.request_history) {
                    metrics.request_history = [];
                }
                metrics.request_history.push(cur_request_metrics);
            });

            return responseData;
        } catch (error) {
            // Handle errors and log metrics for failed requests
            requestEndTime = performance.now();
            requestTime = requestEndTime - requestBeginTime;

            // If compression was attempted
            if (!compressionEndTime && compressionStartTime) {
                compressionEndTime = performance.now();
                compressionTime = compressionEndTime - compressionStartTime;
            }

            if (!trafficSize && finalData) {
                trafficSize = typeof finalData === 'string' ? finalData.length : finalData.byteLength;
                payloadCompressionRatio = uncompressedPayloadSize / trafficSize;
            }
            
            let decomrpessedResponse = "";

            if (error.response) {
                decomrpessedResponse = await decompressResponse(error.response);
                console.error('There is an error with request:', endpoint);
                
                navigate('/error', {
                    state: {
                        htmlCode: decomrpessedResponse,
                    },
                });
            } else {
                console.error('Error', error, typeof(error));
                navigate('/error', {
                    state: { htmlCode: JSON.parse(JSON.stringify(error))},
                });
            }

            // Collect current request metrics for failed request
            let cur_request_metrics = {
                endpoint: endpoint,
                payload: payload,
                response: decomrpessedResponse,

                payload_size: trafficSize || 0,
                uncompressed_payload_size: uncompressedPayloadSize || 0,
                payload_compression_ratio: payloadCompressionRatio || 1,

                response_size: 0,
                uncompressed_response_size: 0,
                response_compression_ratio: 1,

                compression_time: compressionTime || 0,
                decompression_time: 0,
                request_time: requestTime,
                success: false,
                error: error.message || 'Unknown error',
                call_stack: call_stack,
            };

            // Update metrics using updateMetrics function
            updateMetrics((metrics) => {
                const newTotalTraffic = metrics.total_traffic + 1;
                const newTotalPayloadSize = (metrics.total_payload_size || 0) + (trafficSize || 0);
                const newTotalUncompressedPayloadSize = (metrics.total_uncompressed_payload_size || 0) + (uncompressedPayloadSize || 0);
                const newTotalCompressionTime = (metrics.total_compression_time || 0) + (compressionTime || 0);
                const newTotalRequestTime = (metrics.total_request_time || 0) + requestTime;

                metrics.total_traffic = newTotalTraffic;

                metrics.total_payload_size = newTotalPayloadSize;
                metrics.total_uncompressed_payload_size = newTotalUncompressedPayloadSize;
                metrics.avg_payload_size = newTotalPayloadSize / newTotalTraffic;
                metrics.avg_uncompressed_payload_size = newTotalUncompressedPayloadSize / newTotalTraffic;
                metrics.avg_payload_compression_ratio = newTotalUncompressedPayloadSize / newTotalPayloadSize;

                metrics.avg_communication_time =
                    ((metrics.avg_communication_time || 0) * (metrics.total_traffic - 1) + requestTime) / newTotalTraffic;

                metrics.total_compression_time = newTotalCompressionTime;
                metrics.avg_compression_time = newTotalCompressionTime / newTotalTraffic;

                metrics.total_request_time = newTotalRequestTime;
                metrics.avg_request_time = metrics.total_request_time / newTotalTraffic;

                if (!metrics.request_history) {
                    metrics.request_history = [];
                }
                metrics.request_history.push(cur_request_metrics);
            });
            
            return null;
        }
    };

    return request;
};

const groupByTerm = (courses) => {
    if (!Array.isArray(courses)) {
        return {};
    }
    return courses.reduce((acc, course) => {
        if (!acc[course.term]) {
            acc[course.term] = [];
        }
        acc[course.term].push(course);
        return acc;
    }, {});
};

const genericCourse2UserCourse = (generic_course, term) => {
    return {
        code: generic_course.code,
        name: generic_course.name,
        term: term,
        status: generic_course.status,
        area: generic_course.area || "",
        type: generic_course.type,
        twin: generic_course.twin,
        ceab: generic_course.ceab || []
    }
}

function unformatProfileCourses(profile) {
    let temp_courses = []
    if (!profile.courses) {
        return [];
    }

    for (const term of profile.courses) {
        for (const course of term.term_courses) {
            temp_courses.push(genericCourse2UserCourse(course, Number(term.term_name)))
        }
    }

    return temp_courses;
}

function dc(obj) {
    return JSON.parse(JSON.stringify(obj));
}

function log(message) {
    if (process.env.REACT_APP_PRODUCTION === "1") {
        console.log(message);
    }
}

export { server_api_addr, defaultFilterData, compressData, decompressData, decompressResponse, dc, unformatProfileCourses, genericCourse2UserCourse, groupByTerm, requestAuth, termToChar, useRequestWithNavigateJWT, processCourseDetails, termToStr, HTMLRenderer, setItemWithExpiry, getItemWithExpiry, useRequestWithNavigate, calculateDisplayCourseCardWidth, getCourseColor, format_course_data_source, alphanumerical, findCourseLocation, requestCourseDetails, sleep };
