import React, { useEffect, useState } from "react";
import "../Stylesheets/login.css";
import { Input, Button, Typography, Form, Checkbox, Divider } from "antd";
import { useNavigate } from "react-router-dom";
import { setItemWithExpiry, getItemWithExpiry, useRequestWithNavigate } from "../utils";
import axios from "axios";
import LaForgeButton from "./LaforgeButton";

const { Title } = Typography;

function LoginForm({}) {
    return (
        <div className="gradient-background">
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                marginTop: '15%',
                height: '250px'
            }}>
                <h1 style={{
                    fontSize: '4em',
                    color: 'white',
                    zIndex: '10'
                }}>
                    Welcome to smartgellan                
                </h1>
                {/* <Title level={1} style={{ marginTop: "10px" }}>
                    Welcome to smartgellan
                </Title> */}
                <LaForgeButton></LaForgeButton>

                <div style={{
                    width: '25%',
                    marginTop: '25px',
                    zIndex: '10'
                }}>
                    <Divider style={{
                        color: 'white',
                    }}>
                        Team 2024932
                    </Divider>
                </div>
            </div>
        </div>
    );
}

export default LoginForm;

{
    /* <div className="login-container">
<Title level={2} style={{marginTop: '10px'}}>Login</Title>
<form onSubmit={handleSubmit}>
	<div className="form-group">
		<Title level={5}>Email:</Title>
		<Input type="email" value={email} onChange={(e) => setEmail(e.target.value)} required style={{boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)'}}/>
	</div>
	<div className="form-group">
		<Title level={5}>Password:</Title>
		<Input type="password" value={password} onChange={(e) => setPassword(e.target.value)} required style={{boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)'}}/>
	</div>
	{error && <p className="error">{error}</p>}
	<div style={{display: 'flex', justifyContent: 'right'}}>
		<Button type="primary" onClick={(e)=>handleSubmit(e)} style={{width: '75px'}}>Login</Button>
	</div>
</form>
</div> */
}


{/* <Form
    name="basic"
    labelCol={{
        span: 6,
    }}
    wrapperCol={{
        span: 18,
    }}
    style={{
        maxWidth: 600,
    }}
    initialValues={{
        remember: true,
    }}
    onFinish={onFinish}
    onFinishFailed={onFinishFailed}
    autoComplete="on"
>
    <Form.Item
        label="UTORid"
        name="username"
        rules={[
            {
                required: true,
                message: "Please input your UTORid!",
            },
        ]}
    >
        <Input />
    </Form.Item>

    <Form.Item
        label="Password"
        name="password"
        rules={[
            {
                required: true,
                message: "Please input your password!",
            },
        ]}
    >
        <Input.Password />
    </Form.Item>

    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'right', marginTop: '-14px'}}>
        <Form.Item
            name="remember"
            valuePropName="checked"
            wrapperCol={{
                offset: 6,
                span: 18,
            }}
            style={{marginBottom: '10px'}}
        >
            <Checkbox>Remember me</Checkbox>
        </Form.Item>

        <Form.Item
            wrapperCol={{
                offset: 18,
                span: 6,
            }}
        >
            <Button type="primary" htmlType="submit">
                Submit
            </Button>
        </Form.Item>
    </div>
</Form> */}